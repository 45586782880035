// @flow

import type { CreateSessionRQ } from '../../../types/PaymentTypes';

export const buildCreationSessionRQ: CreateSessionRQ = () => ({
  payment: {
    amount: {
      value: JSON.parse(localStorage.getItem('contract')).recommendedPremium.grossPremium.amount,
      currency: JSON.parse(localStorage.getItem('contract')).recommendedPremium.grossPremium.currency || 'EUR',
    },
    paymentHolder: {
      email: JSON.parse(localStorage.getItem('personalDetails')).email,
    },
  },
});
