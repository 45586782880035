// @flow

import { queryParams, siteVersion } from '../../../routes';
import { SINGLE } from '../../../const';
import { toLocalDate } from '../../../dates';

export const buildPurchaseRequest = (matchParams, useBroker, termOfUseValidated, minuteOfConsultationId) => {
  const personalDetailsData = JSON.parse(localStorage.getItem('personalDetails'));
  const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));
  const quotationRS = JSON.parse(localStorage.getItem('quotationRS'));
  const quoteOffer = JSON.parse(localStorage.getItem('contract'));

  const purchaseRequest = {
    dcx: {
      countryCode: matchParams.countryCode,
      partnerCode: matchParams.partnerCode,
      langCode: matchParams.langCode,
      siteVersion,
    },
    payer: {
      firstName: personalDetailsData.firstName,
      lastName: personalDetailsData.lastName,
      title: personalDetailsData.title,
      degree: personalDetailsData.degree,
      address1: personalDetailsData.address,
      address2: personalDetailsData.address2,
      address3: personalDetailsData.address3,
      city: personalDetailsData.city,
      zipCode: personalDetailsData.zipCode,
      countryCode: personalDetailsData.country,
      email: personalDetailsData.email,
      phoneNumber: personalDetailsData.phone,
      dateOfBirth: personalDetailsData.dateOfBirth,
      relationshipStatusWithTravellers: quoteFormData.relationshipStatus,
      individualTravelPrice: quotationRS &&
        quotationRS.contract &&
        quotationRS.contract.contractList &&
        quotationRS.contract.contractList[0] &&
        quotationRS.contract.contractList[0].insuredParties[0] &&
        quotationRS.contract.contractList[0].insuredParties[0].categoryName[0] &&
        quotationRS.contract.contractList[0].insuredParties[0].categoryName[0].perTravelerWithAgeBand &&
        quotationRS.contract.contractList[0].insuredParties[0].categoryName[0].perTravelerWithAgeBand.price ||
        '0',
      isSingle: quoteOffer &&
        quoteOffer.travelersRelationShipType === SINGLE,
    },
    beneficiaries: [buildInsuredPerson(personalDetailsData)]
      .concat(personalDetailsData.passenger && personalDetailsData.passenger.map((trav, index) => ({ ...trav, partyLink: index + 1 })) || [])
      .slice(0, quoteFormData.numberOfTravellers),
    ageBands: buildAgeBands(JSON.parse(localStorage.getItem('quotationRQ'))),
    agent: buildAgentData(useBroker, personalDetailsData),
    trip: {
      startDate: toLocalDate(quoteFormData.dateRange.startDate),
      endDate: toLocalDate(quoteFormData.dateRange.endDate),
      rentalObject: quoteFormData.rentalObject || false,
      tripCost: quoteFormData.tripCost,
      // eslint-disable-next-line no-nested-ternary
      travelType: quoteFormData.tripType === 'single' ? 'one-way-trip' : quoteFormData.tripType === 'annual' ? 'Annual-Trip' : '',
      bookingDate: toLocalDate(quoteFormData.bookingDate),
      originCountryCode: quoteFormData.departureCountry,
      residenceCountry: quoteFormData.residenceCountry,
      destinationCountryCode: quoteFormData.destination,
      destinationLocationType: JSON.parse(localStorage.getItem('quotationRQ')).travels[0].destinations[0].destinationLocationType,
    },
    termOfUseValidated: !!termOfUseValidated,
    minuteOfConsultationId,
    affiliateCode: queryParams.affiliateCode || null,
    agencyCode: queryParams.agency || null,
    taxCode: personalDetailsData.fiscalCode || null,
  };

  // Add discounts only if promoCode is not null or empty
  if (quoteFormData.promoCode) {

    // check promo code is valid
    if (quoteOffer.recommendedPremium.grossPremium.amount < quoteOffer.recommendedPremium.originalGrossPremium.amount) {
      // Promo code is valid and has discount      
      // send the discount code 
      purchaseRequest.discounts = [{
        code: quoteFormData.promoCode,
      }];

      // Send the discount price and the original price
      purchaseRequest.offer = {
        code: quoteOffer.productOfferingSign,
        codeOther: quoteOffer.productOffering.sign[0],
        price: quoteOffer.recommendedPremium.grossPremium.amount,
        originalPrice: quoteOffer.recommendedPremium.originalGrossPremium.amount,
        currency: quoteOffer.recommendedPremium.grossPremium.currency,
      };
      
      console.log('Purchase: Promo code is valid and a discount has been applied');

      // Log the discount percentage to the console
      const discountPercentage = ((purchaseRequest.offer.originalPrice - purchaseRequest.offer.price) / purchaseRequest.offer.originalPrice) * 100;
      console.log(`Purchase: The price is ${purchaseRequest.offer.price.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
      console.log(`Purchase: The Original price is ${purchaseRequest.offer.originalPrice.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);      
      console.log(`Purchase: The discount is ${discountPercentage.toFixed(2)}%`);
    }
    else {      
      // Promo code is invalid or not applicable (price < minimum premium)
      purchaseRequest.offer = {
        code: quoteOffer.productOfferingSign,
        codeOther: quoteOffer.productOffering.sign[0],
        price: quoteOffer.recommendedPremium.grossPremium.amount,
        currency: quoteOffer.recommendedPremium.grossPremium.currency,
      };
      console.log('Purchase: Promo code is invalid');
      console.log(`Purchase: No discount applied, the price is ${quoteOffer.recommendedPremium.grossPremium.amount} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
    }
  }
  else {
    // Send only the original price if no promo code is used
    purchaseRequest.offer = {
      code: quoteOffer.productOfferingSign,
      codeOther: quoteOffer.productOffering.sign[0],
      price: quoteOffer.recommendedPremium.grossPremium.amount,
      currency: quoteOffer.recommendedPremium.grossPremium.currency,
    };
    console.log('Purchase: Promo code feature is disabled');
    console.log(`Purchase: No discount applied, the price is ${quoteOffer.recommendedPremium.grossPremium.amount} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
  }  
  return purchaseRequest;
};


const buildAgeBands = (travellersEqs) => {
  if (!travellersEqs.travels[0].travellers[0].categoryName[0].perTravelerWithAgeBand) {
    return null;
  }
  return travellersEqs.travels[0].travellers[0].categoryName.map(traveller => ({
    code: traveller.perTravelerWithAgeBand.ageCategory,
    numberOfTravelers: traveller.perTravelerWithAgeBand.numberOfTravelers,
  }));
};

const buildAgentData = (useBroker: boolean, personalDetailsData: Object) => {
  if (useBroker) {
    return {
      agencyName: personalDetailsData.agency,
      agentEmail: personalDetailsData.agentEmail,
      agentId: personalDetailsData.agentId,
    };
  }
  return null;
};

const buildInsuredPerson = (personalDetailsData: Object) => {
  if (personalDetailsData.insuredPerson) {
    return {
      partyLink: 0,
      title: personalDetailsData.title,
      degree: personalDetailsData.degree,
      firstName: personalDetailsData.firstName,
      lastName: personalDetailsData.lastName,
      dateOfBirth: personalDetailsData.dateOfBirth,
      passportNumber: personalDetailsData.passportNumber,
      taxCode: personalDetailsData.taxCode,
    };
  }
  return {
    partyLink: 0,
    title: personalDetailsData.insuredTitle,
    degree: personalDetailsData.insuredDegree,
    firstName: personalDetailsData.insuredFirstName,
    lastName: personalDetailsData.insuredLastName,
    dateOfBirth: personalDetailsData.insuredDateOfBirth,
    passportNumber: personalDetailsData.insuredPassportNumber,
    taxCode: personalDetailsData.insuredTaxCode,
  };
};
