/* eslint-disable react/prop-types */
/* eslint-disable max-len */
// @flow

import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

import { CONTACT_US, FORM_STEP_PAGE_ROUTE, HOME_PAGE_ROUTE, NOTICE_PAGE } from '../../const';
import HomeSite from './HomeSite';
import FormStep from '../bookingPath/FormStep';
import Header from './component/Header';
import Footer from './component/Footer';
import ContactUs from '../staticFooter/ContactUs';
import NoticePage from '../staticFooter/NoticePage';

type Props = {
  dispatch: Function,
  match: Object,
  siteData: {
    navigation: Object,
    data: Object,
    allianzLogo: ?string,
    partnerLogo: ?string,
  },
  switches: Object,
}

function HomeSiteRoutes(props: Props) {
  return (
    <Fragment>
      {
        props.siteData.data && props.siteData.data.typeOfSite === 'standAlone' &&
        <Header
          affiliation={props.siteData.affiliation}
          allianzLogo={props.siteData.allianzLogo}
          partnerLogo={props.siteData.partnerLogo}
          data={props.siteData.data}
          header={props.siteData.navigation.header}
          switches={props.switches}
        />
      }
      <Switch>
        <Route exact path={HOME_PAGE_ROUTE} render={() => (<HomeSite {...props} {...props.siteData} />)} />
        <Route path={FORM_STEP_PAGE_ROUTE} render={() => (<FormStep {...props} />)} />
        <Route exact path={CONTACT_US} render={() => (<ContactUs {...props} />)} />
        <Route exact path={NOTICE_PAGE} render={() => (<NoticePage {...props} />)} />
      </Switch>
      {
        props.siteData.data && props.siteData.data.typeOfSite === 'standAlone' &&
        <Footer
          contactUs={props.siteData.navigation}
          noticePage={props.siteData.navigation}
          footerContent={props.siteData.data.footerContent}
          footer={props.siteData.navigation.footer}
        />
      }
    </Fragment>
  );
}

export default withRouter(connect(state => ({
  siteData: state.site.config,
}))(HomeSiteRoutes));
