/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
// @flow

// @flow

import React from 'react';

type Props = {
  input: {
    value: string,
    onChange: Function,
    name: string,
    id: string,
  },
  meta: Object,
  label: string,
  list: string[],
  listType?: string,
  responsive?: boolean,
  statusLabels?: ?Object,
  placeholder: string,
  selectablePlaceholder?: boolean,
  validate: ((Object) => boolean)[],
  disabled: boolean,
  errorMsg?: string,
}

export const LIST_TYPE_NUMBERS = 'numbers';

export default class SelectFieldPreview extends React.PureComponent<Props> {
  render() {
    const {
      listType, list, statusLabels, responsive, placeholder, meta, errorMsg, validate, input, disabled, selectablePlaceholder, label,
    } = this.props;

    const sortFunction = listType === LIST_TYPE_NUMBERS ? (a, b) => parseInt(a, 10) - parseInt(b, 10)
      : (a, b) => a.toString().localeCompare(b.toString(), 'en');
    const keys = statusLabels ? Object.keys(statusLabels) : list.sort(sortFunction);

    const errorId = `${input.name}-error`;

    return (
      <div className={`preview-field ${responsive ? 'preview-field--responsive' : ''}`}>
        <label htmlFor={input.id} className="label">{label}</label>
        <select
          {...input}
          required={!!validate}
          aria-describedby={meta.error && meta.touched ? errorId : undefined}
          aria-invalid={meta.error && meta.touched ? 'true' : 'false'}
          aria-label={input.name}
          disabled={disabled}
          id={input.id}
        >
          <option
            value=""
            className="placeholder-option"
            disabled={!selectablePlaceholder}
            aria-disabled={!selectablePlaceholder}
          >{placeholder}
          </option>
          {
            keys.map((elem, i) => (
              <option
                key={`${input.name}-${elem}-${i}`}
                value={elem}
              >{statusLabels ? statusLabels[elem] : elem}
              </option>
            ))
          }
        </select>
        {meta.error && meta.touched && (
          <span id={errorId} className="text-error" role="alert">
            {errorMsg || meta.error}
          </span>
        )}
      </div>
    );
  }
}

SelectFieldPreview.defaultProps = {
  responsive: false,
  statusLabels: null,
  errorMsg: '',
};
