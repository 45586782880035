// @flow

import 'font-awesome/css/font-awesome.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// pour IE 11 uniquement
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import App from './App';
import './css/app.css';
import fr from './i18n/locales/fr';
import en from './i18n/locales/en';
import reducers from './state/reducers';

const store = createStore(
  reducers,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk),
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations({
  fr,
  en,
}));
store.dispatch(setLocale('en'));

/* eslint-disable react/jsx-filename-extension */
function wrapApp(RootComponent, reduxStore) {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={RootComponent} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

// $FlowFixMe
ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
