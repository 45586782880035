// @flow

import React from 'react';

type Props = {
  input: {
    value: string,
    onChange: Function,
  },
  label: string | React.ReactNode,
  meta: Object,
  errorMsg: string,
  trueVal: string,
  falseVal: string,
}

type State = {
  selected: boolean
}

class ResidentCountryCheckbox extends React.Component<Props, State> {
  state = {
    selected: false,
  };

  static getDerivedStateFromProps(props: Props) {
    return {
      selected: props.input.value === (props.trueVal || true),
    };
  }

  handleChange = () => {
    if (this.state.selected) {
      this.setState({ selected: false });
      this.props.input.onChange(this.props.falseVal || false);
    } else {
      this.setState({ selected: true });
      this.props.input.onChange(this.props.trueVal || true);
    }
  };

  componentDidMount() {
    this.handleChange();
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      this.handleChange();
    }
  };

  render() {
    // Generate a unique ID for the label and checkbox
    const uniqueId = `resident-country-checkbox-${this.props.input.name}`;

    return (
      <div className="field-radio">
        <div className="container-radio">
          <div className="input-radio input-radio--lonely">
            <div
              className={`check-simul ${this.state.selected ? 'check-simul--selected' : 'check-simul'}`}
              onClick={this.handleChange}
              onKeyDown={this.handleKeyDown}
              role="checkbox"
              aria-checked={this.state.selected}
              aria-labelledby={uniqueId} // Associate the label with this control
              tabIndex={0}
            />
            <label
              id={uniqueId} // Set the ID for the label to associate with the checkbox
              className="label"
              onClick={this.handleChange}
            >
              {this.props.label}
            </label>
          </div>
        </div>
        {this.props.meta.error && this.props.meta.touched &&
          <span className="text-error">{this.props.errorMsg || this.props.meta.error}</span>}
      </div>
    );
  }
}

export default ResidentCountryCheckbox;
