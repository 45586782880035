// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleConfirmationWithModal } from '../../state/notifications/notificationsActions';

type Props = {
  isConfirmationNotification: boolean,
};

function ConfirmationModal({ isConfirmationNotification }: Props) {
  return (
    <div
      id="confirmationModal"
      className="hidden"
      onClick={() => toggleConfirmationWithModal(false)}
      role="Alertdialog"
      aria-label="Confirmation"
    />

  );
}

export default withRouter(connect(
  state => ({
  }),
  { toggleConfirmationWithModal },
)(ConfirmationModal));
