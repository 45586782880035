// @flow

import moment from 'moment';
import { extractContractDestination, extractDocumentsUrl } from '../../../network/api/eqsApi';

export const buildPrebooking = (personalDetails, matchParams, siteData) => {
  const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));
  const quoteOffer = JSON.parse(localStorage.getItem('contract'));
  const highlightedOffer = findHighlightedOffer();
  
  const prebookingData = {
    site: {
      country: matchParams.countryCode,
      partner: matchParams.partnerCode,
      lang: matchParams.langCode,
      lob: siteData.data.lineOfBusiness,
    },
    emailSendingConfig: personalDetails.email,
    bookedBy: personalDetails.agentId,
    agent: {
      email: personalDetails.agentEmail,
      code: personalDetails.agentId,
      agencyCode: personalDetails.agency,
    },
    issueDate: moment(),
    travel: {
      type: quoteFormData.tripType === 'single' ? 'one-way-trip' : quoteFormData.tripType === 'annual' ? 'Annual-Trip' : '',
      geozone: extractContractDestination(quoteOffer),
      startDate: formatToUTC(quoteFormData.dateRange.startDate),
      endDate: formatToUTC(quoteFormData.dateRange.endDate),
      destinationLocationName: quoteFormData.destination,
      destinationLocationType: siteData.quoteForm.mainQuoteData.destination === 'countries' ? 'country' : 'zone',
      bookingDate: formatToUTC(quoteFormData.bookingDate),
      currency: quoteOffer.recommendedPremium.grossPremium.currency,
      totalAmount: quoteOffer.recommendedPremium.grossPremium.amount,
      totalIndividualAmount: quoteFormData.tripCost,
      productName: quoteOffer.productOfferingName,
      productSign: quoteOffer.productOfferingSign,
      insuredPersonType: quoteOffer.travelersRelationShipType || 'Single',
      insuredPersonPeriod: quoteFormData.tripType === 'single' ? 'one-way-trip' : quoteFormData.tripType === 'annual' ? 'Annual-Trip' : quoteFormData.tripType,
      preferredOfferName: !!highlightedOffer && highlightedOffer.length !== 0 && highlightedOffer[0].productOfferingName || null,
      preferredOfferPrice: !!highlightedOffer && highlightedOffer.length !== 0 && highlightedOffer[0].recommendedPremium.grossPremium.amount || null,
    },
    payer: {
      firstName: personalDetails.firstName,
      lastName: personalDetails.lastName,
      title: personalDetails.title,
      address: buildAddress(personalDetails),
      city: personalDetails.city,
      zipCode: personalDetails.zipCode,
      countryCode: personalDetails.country,
    },
    documents: extractDocumentsUrl(quoteOffer),
    beneficiaries: [buildInsuredPerson(personalDetails)]
      .concat(personalDetails.passenger || [])
      .slice(0, quoteFormData.numberOfTravellers),
  };

  // Add discounts only if promoCode is not null or empty
  if (quoteFormData.promoCode) {
    // check promo code is valid
    if (quoteOffer.recommendedPremium.grossPremium.amount < quoteOffer.recommendedPremium.originalGrossPremium.amount) {            
      // Promo code is valid and has discount      
      // send the discount code 
      prebookingData.discounts = [{
        code: quoteFormData.promoCode,
      }];  
      // Promo code is invalid or not applicable (price < minimum price)
      console.log('Prebooking: Promo code is valid and has discount');

      // Log the discount percentage to the console
      const discountPercentage = ((quoteOffer.recommendedPremium.originalGrossPremium.amount - quoteOffer.recommendedPremium.grossPremium.amount ) / quoteOffer.recommendedPremium.originalGrossPremium.amount) * 100;
      console.log(`Prebooking: The price is ${quoteOffer.recommendedPremium.grossPremium.amount.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
      console.log(`Prebooking: The Original price is ${quoteOffer.recommendedPremium.originalGrossPremium.amount.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
      console.log(`Prebooking: The discount is ${discountPercentage.toFixed(2)}%`);
    }
    else {      
      console.log('Prebooking: Promo code is invalid');
      console.log(`Prebooking: No discount applied, the price is ${quoteOffer.recommendedPremium.grossPremium.amount.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
    }
  }
  else {
    console.log('Prebooking: Promo code feature is disabled');
    console.log(`Prebooking: No discount applied, the price is ${quoteOffer.recommendedPremium.grossPremium.amount.toFixed(2)} ${quoteOffer.recommendedPremium.grossPremium.currency}`);
  }
  return prebookingData;
};

const buildInsuredPerson = (personalDetailsData: Object) => {
  if (personalDetailsData.insuredPerson) {
    return {
      title: personalDetailsData.title,
      degree: personalDetailsData.degree,
      firstName: personalDetailsData.firstName,
      lastName: personalDetailsData.lastName,
      dateOfBirth: personalDetailsData.dateOfBirth,
    };
  }
  return {
    title: personalDetailsData.insuredTitle,
    degree: personalDetailsData.insuredDegree,
    firstName: personalDetailsData.insuredFirstName,
    lastName: personalDetailsData.insuredLastName,
    dateOfBirth: personalDetailsData.insuredDateOfBirth,
  };
};

const findHighlightedOffer = () => {
  const quotationRS = JSON.parse(localStorage.getItem('quotationRS'));
  return quotationRS && quotationRS.contract && quotationRS.contract.contractList.filter(contract => contract.productOffering.highlightedOffer);
};

const buildAddress = (personalDetails) => {
  const arr = [personalDetails.address, personalDetails.address2, personalDetails.address3];
  return arr.filter(n => n).join(', ');
};

const formatToUTC = (dateTime: string): string => {
  // Create a Date object from the input string
  const date = new Date(dateTime);

  // Get the time in UTC format
  const utcYear = date.getFullYear();
  const utcMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const utcDay = String(date.getDate()).padStart(2, '0');
  const utcHours = String(date.getHours()).padStart(2, '0');
  const utcMinutes = String(date.getMinutes()).padStart(2, '0');
  const utcSeconds = String(date.getSeconds()).padStart(2, '0');
  const utcMilliseconds = String(date.getMilliseconds()).padStart(3, '0');

  // Format the date to the desired string format
  return `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}Z`;
};