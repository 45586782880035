/* eslint-disable react/self-closing-comp */
/* eslint-disable one-var */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// @flow

import React, { Fragment } from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { format } from 'react-string-format';
import moment from 'moment';
import TextInput from '../../homeSite/component/quoteFormComponents/TextInput';
import { required, validateEmail } from '../../../components/validation/ValidationFunctions';
import SelectFieldPreview from '../../homeSite/component/quoteFormComponents/SelectFieldPreview';
import DatePickerQuoteForm from '../../homeSite/component/quoteFormComponents/DatePickerQuoteForm';
import CustomCheckbox from '../../homeSite/component/CustomCheckbox';
import { loadCountriesIfNeeded } from '../../../state/quoteForm/quoteFormActions';
import { nonNull } from '../../../utils';
import { pastYears } from '../../../components/CustomDatePicker';
import Loader from '../../../components/Loader';
import type { FieldBookingPath } from '../../../const';
import { childCodes, fieldBookingPath, getBookingMapValueForCountry } from '../../../const';
import { getEnvironment } from '../../../helper/helper';

type Props = {
  stepInfos: Object,
  breadcrumb: {},
  match: Object,
  dispatch: Function,
  country: string,
  countries: Object[],
  resetPurchaseError: Function,
}

type State = {
  numberOfTravellers: number,
}

const getTravellersChildAdult = () => {
  // eslint-disable-next-line prefer-destructuring
  const travellers = JSON.parse(localStorage.getItem('quoteFormFront')).travellers;
  let adult = 0,
    child = 0;
  let number = true;
  let arrayChildAdult = [];
  if (typeof travellers === 'object') {
    Object.keys(travellers).forEach(key => {
      // eslint-disable-next-line no-unused-expressions
      childCodes.includes(key) ? child += parseInt(travellers[key], 10) : adult += parseInt(travellers[key], 10);
    });
    number = false;
    const arrayAdult = new Array(parseInt(adult, 10));
    arrayAdult.fill('ADULT');
    const arrayChild = new Array(parseInt(child, 10));
    arrayChild.fill('CHILD');
    arrayChildAdult = [...arrayAdult, ...arrayChild];
  } else { // if no agebands, we consider its adult persons
    arrayChildAdult = new Array(parseInt(travellers, 10));
    arrayChildAdult.fill('ADULT');
  }
  return {
    number,
    arrayChildAdult,
  };
};

class PersonalDetails extends React.Component<Props, State> {
  state = {
    numberOfTravellers: 1,
  };

  getDateDiff = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const result = moment(newStartDate).diff(newEndDate, 'days');
    return result + 1;
  };

  componentDidMount() {
    this.props.dispatch(loadCountriesIfNeeded(this.props.match.params.langCode));
    this.setState({ numberOfTravellers: JSON.parse(localStorage.getItem('quoteFormFront')).numberOfTravellers });

    //! ECOMDEV-40085 - Adobe script implementation
    const quoteFormFront = JSON.parse(localStorage.getItem('quoteFormFront'));
    const quotationRQ = JSON.parse(localStorage.getItem('quotationRQ'));    
    const contract = JSON.parse(localStorage.getItem('contract'));

    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Page Title
    if (this.props.breadcrumb.quoteStep5 === null || this.props.breadcrumb.quoteStep5 === undefined) {
      document.title = this.props.breadcrumb.quoteStep2;
    } else {
      document.title = this.props.breadcrumb.quoteStep3;
    }

    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Personal Details',
          pageType: 'Personal Details',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
        product: [{
          id: contract.productOfferingSign, // KLMANNWL
          name: contract.productOfferingName, // "Copertura Annullamento "
          price: contract.recommendedPremium.grossPremium.amount,
          quantity: 1,
        }],
        quote: {
          coverage: {
            daysBeforeCoverageStart: this.getDateDiff(quoteFormFront.dateRange.startDate, quoteFormFront.bookingDate), // booking date - start date
            duration: this.getDateDiff(quoteFormFront.dateRange.endDate, quoteFormFront.dateRange.startDate), // end date - start date
            endDate: quotationRQ.travels[0].destinations[0].destinationDate,
            startDate: quotationRQ.travels[0].originDate,
            objects: {
              coverageRegion: quoteFormFront.destination, // BU or Region
              coverageOptions: quotationRQ.travels[0].travelType, // one-way-trip
            },
            persons: {
              insuredPersons: quoteFormFront.travellers, // age band - "infant:0|child:0|youth:0|adult:0|senior:0|oldSenior:0" or Number of passenger - "1"
              numberOfPersons: quoteFormFront.numberOfTravellers, // Recheck case passenger > 1 & age band
            },
          },
          policyHolder: {
            residenceCountry: quotationRQ.travels[0].residence.countryCode,
          },
          quoteDetails: {
            date: quotationRQ.travels[0].bookingDate,
            insuredCost: quotationRQ.travels[0].totalValue.amount ? quotationRQ.travels[0].totalValue.amount : 0, // Trip Cost - Default 0 if no trip cost on quote form
            primaryCategory: quotationRQ.travels[0].travelType,
          },
        },
      },
    };
    // eslint-disable-next-line no-underscore-dangle
    window._satellite.track('scCheckout');

    // console.log('-- Personal Details --');
    // console.log(window.digitalData);
  }

  componentWillUnmount() {
    this.props.resetPurchaseError();
  }

  // eslint-disable-next-line arrow-body-style
  getBookingMapValueForCountry = (fieldBookingPath: FieldBookingPath) => {
    return getBookingMapValueForCountry(this.props.match.params.countryCode, fieldBookingPath);
  };

  render() {
    if (!this.props.stepInfos || !this.props.data) {
      return false;
    }

    const buildListDegree = () => {
      const list = [
        this.props.stepInfos.personalDetailsTitle1,
        this.props.stepInfos.personalDetailsTitle2,
        this.props.stepInfos.personalDetailsTitle3,
      ];
      if (this.props.consts.degreeCountries.indexOf(this.props.match.params.countryCode) !== -1) {
        list.push(
          this.props.stepInfos.personalDetailsDegree1,
          this.props.stepInfos.personalDetailsDegree2,
          this.props.stepInfos.personalDetailsDegree3,
          this.props.stepInfos.personalDetailsDegree4,
        );
      }
      return list;
    };

    const { arrayChildAdult } = getTravellersChildAdult();

    return (
      <div className="loader-container">
        {
          this.props.loading &&
          <div className="layer-loading">
            <Loader />
          </div>
        }
        <div className="header">
          <span>{this.props.stepInfos.personalDetailsPolicyHolderTitle || 'Policy Holder Information'}</span>
        </div>
        <div className="field-line">
          <Field
            key="insuredPerson"
            name="insuredPerson"
            label={this.props.stepInfos.personalDetailsConfirmInsuredCheckbox || I18n.t('bookingPathForm.personnalDetails.insuredPerson')}
            component={CustomCheckbox}
            errorMsg={this.props.stepInfos.personalDetailsErrorTitle}
          />
        </div>
        <div className="field-line">
          <Field
            component={SelectFieldPreview}
            placeholder={this.props.stepInfos.personalDetailsTitle || 'Title'}
            name="title"
            validate={[required]}
            errorMsg={this.props.stepInfos.personalDetailsErrorTitle}
            list={buildListDegree().filter(nonNull)}
          />

          <div />
        </div>

        <div className="field-line">
          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsFirstName || 'First Name'}
            name="firstName"
            errorMsg={this.props.stepInfos.personalDetailsErrorFirstName}
            validate={[required]}
          />

          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsLastName || 'Last Name'}
            name="lastName"
            errorMsg={this.props.stepInfos.personalDetailsErrorLastName}
            validate={[required]}
          />
          {
            this.getBookingMapValueForCountry(fieldBookingPath.dateOfBirth) &&
            <Field
              component={DatePickerQuoteForm}
              years={pastYears}
              date={this.props.stepInfos.personalDetailsDateOfBirth}
              name="dateOfBirth"
              errorMsg={this.props.stepInfos.personalDetailsErrorDateOfBirth}
              validate={[required]}
            />
          }
        </div>
        <div className="field-line">
          {
            this.getBookingMapValueForCountry(fieldBookingPath.passport) &&
            <Field
              component={TextInput}
              placeholder={this.props.stepInfos.personalDetailsPassportNumber || 'Passport number'}
              name="passportNumber"
              errorMsg={this.props.stepInfos.personalDetailsPassportNumberError}
              validate={[required]}
            />
          }
        </div>
        <Field
          component={TextInput}
          placeholder={this.props.stepInfos.personalDetailsAddress || 'Address'}
          name="address"
          errorMsg={this.props.stepInfos.personalDetailsErrorAddress}
          validate={[required]}
        />
        {
          this.getBookingMapValueForCountry(fieldBookingPath.address2) &&
          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsAddress2 || 'Address 2'}
            name="address2"
          />
        }
        {
          this.getBookingMapValueForCountry(fieldBookingPath.address3) &&
          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsAddress3 || 'Address 3'}
            name="address3"
          />
        }

        <div className="field-line">
          {
            this.getBookingMapValueForCountry(fieldBookingPath.city) &&
            <Field
              component={TextInput}
              placeholder={this.props.stepInfos.personalDetailsCity || 'City'}
              name="city"
              errorMsg={this.props.stepInfos.personalDetailsErrorCity}
              validate={[required]}
            />
          }
          {
            this.getBookingMapValueForCountry(fieldBookingPath.zipCode) &&
            <Field
              component={TextInput}
              placeholder={this.props.stepInfos.personalDetailsPostCode || 'Zip code'}
              name="zipCode"
              errorMsg={this.props.stepInfos.personalDetailsErrorPostCode}
              validate={[required]}
            />
          }
          {
            this.getBookingMapValueForCountry(fieldBookingPath.state) &&
            <Field
              component={SelectFieldPreview}
              placeholder={this.props.stepInfos.personalDetailsResidenceCountry || 'Country'}
              name="country"
              errorMsg={this.props.stepInfos.personalDetailsErrorResidenceCountry}
              validate={[required]}
              statusLabels={this.props.countries.reduce((map, country) => {
                // eslint-disable-next-line no-param-reassign
                map[country.countryCode] = country.countryName;
                return map;
              }, {})}
              disabled
              onChange={() => this.props.dispatch(change('stepForm', 'fiscalCode', ''))}
            />
          }
        </div>

        <div className="field-line">
          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsPhone || 'Phone'}
            name="phone"
            errorMsg={this.props.stepInfos.personalDetailsErrorPhone}
            validate={[required]}
          />
          <Field
            component={TextInput}
            placeholder={this.props.stepInfos.personalDetailsEmail || 'Email'}
            name="email"
            errorMsg={this.props.stepInfos.personalDetailsErrorEmail}
            validate={[required, validateEmail]}
          />
          <div />
        </div>

        <div className="field-line">
          {
            this.getBookingMapValueForCountry(fieldBookingPath.tax) &&
            this.props.consts.fiscalCodeBU.indexOf(this.props.match.params.countryCode) !== -1 &&
            this.props.consts.fiscalCodeResidenceCountry.indexOf(this.props.country) !== -1 &&
            <Field
              component={TextInput}
              placeholder={this.props.stepInfos.personalDetailsFiscalCode || 'Tax Code'}
              name="fiscalCode"
              errorMsg={this.props.stepInfos.personalDetailsErrorFiscalCode}
              validate={[required]}
            />
          }
        </div>
        {/* insuredPerson (if checkbox is unchecked) */}
        {!this.props.insuredPerson &&
        <Fragment>
          <div className="header">
            <span>{this.props.stepInfos.personalDetailsInsuredTitle || 'Insured person Information'}</span>
          </div>
          <h3>{this.props.stepInfos.personalDetailsOtherPassenger} 1</h3>
          <div className="field-line">
            <Field
              component={SelectFieldPreview}
              placeholder={this.props.stepInfos.personalDetailsTitle || 'Title'}
              name="insuredTitle"
              validate={[required]}
              list={buildListDegree().filter(nonNull)}
            />
            <div />
          </div>
          <div className="field-line">
            <Field
              component={TextInput}
              placeholder={this.props.stepInfos.personalDetailsLastName || 'Last Name'}
              errorMsg={this.props.stepInfos.personalDetailsErrorLastName}
              name="insuredLastName"
              validate={[required]}
            />
            {
              this.getBookingMapValueForCountry(fieldBookingPath.otherFirstName) &&
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsFirstName || 'First Name'}
                errorMsg={this.props.stepInfos.personalDetailsErrorFirstName}
                name="insuredFirstName"
                validate={[required]}
              />
            }
            {
              this.getBookingMapValueForCountry(fieldBookingPath.otherDateOfBirth) &&
              <Field
                component={DatePickerQuoteForm}
                years={pastYears}
                name="insuredDateOfBirth"
                date={this.props.stepInfos.personalDetailsDateOfBirth}
                errorMsg={this.props.stepInfos.personalDetailsErrorDateOfBirth}
              />
            }
          </div>

          <div className="field-line">
            {
              this.getBookingMapValueForCountry(fieldBookingPath.passport) &&
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsPassportNumber || 'Passport number'}
                name="insuredPassportNumber"
                errorMsg={this.props.stepInfos.personalDetailsPassportNumberError}
                validate={[required]}
              />
            }
            {
              this.getBookingMapValueForCountry(fieldBookingPath.otherTaxCode) &&
              <Field
                component={TextInput}
                years={pastYears}
                placeholder={this.props.stepInfos.personalDetailsOtherErrorTaxCode || 'Tax code'}
                name="insuredTaxCode"
                errorMsg={this.props.stepInfos.personalDetailsOtherErrorTaxCode}
              />
            }
          </div>
        </Fragment>
        }
        {/* other passengers */}
        {this.props.consts.contractHolderOnlyLOB.indexOf(this.props.LOB) === -1 &&
        new Array(this.state.numberOfTravellers > 0 ? this.state.numberOfTravellers - 1 : 0).fill(1).map((passenger, index) => (
          <div key={passenger * Math.random()}>
            <h3>{this.props.stepInfos.personalDetailsOtherPassenger} {index + 2}</h3>
            <div className="field-line">
              <Field
                component={SelectFieldPreview}
                placeholder={this.props.stepInfos.personalDetailsOtherTitle || 'Title'}
                name={`passenger[${index}].title`}
                validate={[required]}
                errorMsg={this.props.stepInfos.personalDetailsOtherErrorTitle}
                list={buildListDegree().filter(nonNull)}
              />
              <div />
            </div>
            <div className="field-line">
              {
                this.getBookingMapValueForCountry(fieldBookingPath.otherFirstName) &&
                <Field
                  component={TextInput}
                  placeholder={this.props.stepInfos.personalDetailsOtherFirstName || 'First Name'}
                  name={`passenger[${index}].firstName`}
                  validate={[required]}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorFirstName}
                />
              }
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsOtherLastName || 'Last Name'}
                name={`passenger[${index}].lastName`}
                validate={[required]}
                errorMsg={this.props.stepInfos.personalDetailsOtherErrorLastName}
              />
              {
                this.getBookingMapValueForCountry(fieldBookingPath.otherDateOfBirth) &&
                <Field
                  component={DatePickerQuoteForm}
                  years={pastYears}
                  placeholder={this.props.stepInfos.personalDetailsOtherDateOfBirth || 'Birth date'}
                  validate={[required]}
                  name={`passenger[${index}].dateOfBirth`}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorDateOfBirth}
                />
              }
            </div>

            <div className="field-line">
              {
                this.getBookingMapValueForCountry(fieldBookingPath.otherLegalTitle) &&
                <Field
                  component={SelectFieldPreview}
                  placeholder={this.props.stepInfos.personalDetailsOtherLegalTitle || 'Title'}
                  name={`passenger[${index}].legalTitle`}
                  validate={[required]}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorLegalTitle}
                  list={buildListDegree().filter(nonNull)}
                />
              }
              <div />
            </div>
            <div className="field-line">
              {
                this.getBookingMapValueForCountry(fieldBookingPath.otherLegalLastName) &&
                arrayChildAdult[index + 1] === 'CHILD' &&
                <Field
                  component={TextInput}
                  placeholder={this.props.stepInfos.personalDetailsOtherLastName || 'Last Name'}
                  name={`passenger[${index}].legalLastName`}
                  validate={[required]}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorLastName}
                />
              }
              {
                this.getBookingMapValueForCountry(fieldBookingPath.otherLegalFirstName) &&
                arrayChildAdult[index + 1] === 'CHILD' &&
                <Field
                  component={TextInput}
                  placeholder={this.props.stepInfos.personalDetailsOtherFirstName || 'First Name'}
                  name={`passenger[${index}].legalFirstName`}
                  validate={[required]}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorFirstName}
                />
              }
            </div>
            <div className="field-line">
              {
                this.getBookingMapValueForCountry(fieldBookingPath.passport) &&
                <Field
                  component={TextInput}
                  placeholder={this.props.stepInfos.personalDetailsOtherPassportNumber || 'Passport number'}
                  name={`passenger[${index}].passportNumber`}
                  errorMsg={this.props.stepInfos.personalDetailsOtherPassportNumberError}
                  validate={[required]}
                />
              }
            </div>

            {
              this.getBookingMapValueForCountry(fieldBookingPath.otherLegalTaxCode) &&
              arrayChildAdult[index + 1] === 'CHILD' &&
              <div className="field-line">
                <Field
                  component={TextInput}
                  placeholder={this.props.stepInfos.personalDetailsOtherLegalTaxCode || 'Legal representative tax code'}
                  validate={[required]}
                  name={`passenger[${index}].legalTaxCode`}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorLegalTaxCode}
                />
              </div>
            }
            {
              arrayChildAdult[index + 1] === 'CHILD' &&
              <React.Fragment>
                <div className="field-line">
                  {
                    this.getBookingMapValueForCountry(fieldBookingPath.otherLegalLastName) &&
                    <Field
                      component={TextInput}
                      placeholder={this.props.stepInfos.personalDetailsOtherLegalLastName || 'Legal representative last name'}
                      validate={[required]}
                      name={`passenger[${index}].legalLastName`}
                      errorMsg={this.props.stepInfos.personalDetailsOtherErrorLastName}
                    />
                  }
                  {
                    this.getBookingMapValueForCountry(fieldBookingPath.otherLegalFirstName) &&
                    <Field
                      component={TextInput}
                      validate={[required]}
                      placeholder={this.props.stepInfos.personalDetailsOtherLegalFirstName || 'Legal representative first name'}
                      name={`passenger[${index}].legalFirstName`}
                      errorMsg={this.props.stepInfos.personalDetailsOtherErrorFirstName}
                    />
                  }
                </div>
              </React.Fragment>
            }
            {
              arrayChildAdult[index + 1] === 'ADULT' &&
              this.getBookingMapValueForCountry(fieldBookingPath.otherTaxCode) &&
              <div className="field-line">
                <Field
                  component={TextInput}
                  years={pastYears}
                  placeholder={this.props.stepInfos.personalDetailsOtherErrorTaxCode || 'Tax code'}
                  name={`passenger[${index}].taxCode`}
                  errorMsg={this.props.stepInfos.personalDetailsOtherErrorTaxCode}
                />
              </div>
            }
          </div>
        ))
        }
        {
          this.state && this.props.data.broker &&
          <Fragment>
            <div className="header">
              <span>{this.props.stepInfos.personalDetailsBrokerInfoTitle || 'Broker Information'}</span>
            </div>

            <div className="field-line">
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsBrokerAgentId || 'Agent ID'}
                errorMsg={this.props.stepInfos.personalDetailsBrokerErrorAgentId}
                name="agentId"
                validate={(this.props.data.broker && !this.props.data.directCustomers) ? [required] : []}
              />
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsBrokerAgentEmail || 'Agent Email'}
                errorMsg={this.props.stepInfos.personalDetailsBrokerErrorAgentEmail}
                name="agentEmail"
                validate={(this.props.data.broker && !this.props.data.directCustomers) ? [required, validateEmail] : [validateEmail]}
              />
              <Field
                component={TextInput}
                placeholder={this.props.stepInfos.personalDetailsBrokerAgency || 'Agency'}
                errorMsg={this.props.stepInfos.personalDetailsBrokerErrorAgency}
                name="agency"
                validate={(this.props.data.broker && !this.props.data.directCustomers) ? [required] : []}
              />
              <div />
            </div>
          </Fragment>
        }
        {this.props.displayPurchaseError &&
        <div className="error-purchase">{this.props.stepInfos.personalDetailsPurchaseError}</div>
        }
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  insuredPerson: formValueSelector('stepForm')(state, 'insuredPerson'),
  country: formValueSelector('stepForm')(state, 'country'),
  countries: state.quoteForm.countries,
}))(PersonalDetails));
