// @flow

import React from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment/moment';
import CustomDatePicker from '../../../../components/CustomDatePicker';

type Props = {
  input: {
    value: Date,
    onChange: Function,
    name: string,
  },
  meta: Object,
  date: Date,
  label: string,
  calendarIcon: boolean,
  years: number[],
}

type State = {
  focused: ?boolean,
}

export default class DatePickerQuoteForm extends React.Component<Props, State> {
  state = {
    focused: null,
  };

  render() {
    return (
      <div className="preview-field preview-field--pad">
        <CustomDatePicker
          label={this.props.label}
          showDefaultInputIcon={this.props.calendarIcon}
          isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
          date={this.props.input.value ? moment(this.props.input.value) : moment()}
          onDateChange={date => this.props.input.onChange(date)}
          focused={this.state.focused}
          onFocusChange={({ focused }) => {
            this.setState({ focused });
          }}
          // eslint-disable-next-line react/prop-types
          placeholder={this.props.placeholder || this.props.date}
          years={this.props.years}
        />
        {this.props.meta.error && this.props.meta.touched &&
        // eslint-disable-next-line react/prop-types
        <span className="text-error">{this.props.errorMsg || this.props.meta.error}</span>}
      </div>
    );
  }
}
