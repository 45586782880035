/* eslint-disable max-len */
// @flow

import type { DCXType } from '../../types/DCXTypes';

export const STORE_DCX = 'STORE_DCX';
export const SITE_CONFIG = 'SITE_CONFIG';

export function storeDCX(DCX: DCXType) {
  return {
    type: STORE_DCX,
    payload: {
      DCX,
    },
  };
}

export function siteConfigAction(siteConfig: Object) {
  return {
    type: SITE_CONFIG,
    payload: {
      siteConfig,
    },
  };
}
