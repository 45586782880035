/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import MultiSelectPassenger from './MultiSelectPassenger';
import SingleSelectPassenger from './SingleSelectPassenger';

type Props = {
  input: Object,
  label: string,
  list: string[],
  meta: Object,
  travellers: string,
  agebandLabels: Object,
  quoteData: Object,
  saveLabel: string,
  clearLabel: string,
}

const initFields = (travellers, agebandLabels) => {
  if (travellers && travellers !== 'number') {
    return travellers.split('/').map(ageband => ({
      name: ageband,
      label: agebandLabels[ageband],
      labelDesc: agebandLabels[`${ageband}Desc`],
    }));
  }
};

export default class NumberOfTravellers extends React.Component<Props> {
  state = {
    fields: initFields(this.props.travellers, this.props.agebandLabels),
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    this.setState({ fields: initFields(nextProps.travellers, nextProps.agebandLabels) });
  }
  render() {
    const {
      travellers, label, quoteData, travellersWording, meta, input,
    } = this.props;
    return (
      <div className="preview-field">
        <div className="label">{label}</div>
        {
          travellers !== 'number' &&
          <Field
            name={input.name}
            component={MultiSelectPassenger}
            fields={this.state.fields}
            placeholder={quoteData.quoteLabelTranslation.selectNumber || I18n.t('placeholder.number')}
            changeForm={(fieldName, values) => {
              this.props.changeForm(fieldName, values);
            }}
            travellersWording={travellersWording || 'travellers'}
          >
          </Field>
        }

        {
          travellers === 'number' &&
          <Field
            name={input.name}
            component={SingleSelectPassenger}
            fields={this.state.fields}
            placeholder={quoteData.quoteLabelTranslation.selectNumber || I18n.t('placeholder.number')}
            changeForm={(fieldName, values) => {
              this.props.changeForm(fieldName, values);
            }}
          >
          </Field>
        }
        {meta.error && meta.touched &&
        <span className="text-error">{meta.errorMsg || meta.error}</span>}
      </div>
    );
  }
}
