/* eslint-disable react/prop-types */
// @flow

import React from 'react';

type Props = {
  label: string,
  input: Object,
  placeholder: string,
  meta: Object,
  errorMsg: string,
  min?: string,  // assuming you want to allow min to be passed in
  type?: string, // assuming you want to allow type to be passed in
}

export default function TextInput(props: Props) {
  const { label, input, placeholder, meta, errorMsg, min, type } = props;
  const inputId = `input-${input.name}`; // Unique ID for input field
  const errorId = `error-${input.name}`; // Unique ID for error message

  return (
    <div className="preview-field">
      <label className="label" htmlFor={inputId}>
        {label}
      </label>
      <input
        {...input}                // spread input-related props only
        id={inputId}              // unique ID for input element
        min={min}                 // explicitly pass min if needed
        aria-describedby={meta.error && meta.touched ? errorId : null} // describes input
        aria-invalid={meta.error && meta.touched ? 'true' : 'false'}   // indicates if input is invalid
        aria-required={input.required ? 'true' : 'false'} // marks as required if applicable
        type={type || 'text'}     // default to 'text' if type is not provided
        placeholder={placeholder} // explicitly pass placeholder
      />
      {meta.error && meta.touched && (
        <span className="text-error" id={errorId} aria-live="assertive">
          {errorMsg || meta.error}
        </span>
      )}
    </div>
  );
}
