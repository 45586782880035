/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';
import type { QuoteFormType } from '../../../types/QuoteFormTypes';
import { LOBS } from '../../../types/QuoteFormTypes';
import ContainerRadio from './quoteFormComponents/ContainerRadio';
import SelectFieldPreview from './quoteFormComponents/SelectFieldPreview';
import DatesOfTrip from './quoteFormComponents/DatesOfTrip';
import AnnualDatesOfTrip from './quoteFormComponents/AnnualDatesOfTrip';
import DatePickerQuoteForm from './quoteFormComponents/DatePickerQuoteForm';
import NumberOfTravellers from './quoteFormComponents/NumberOfTravellers';
import TextInput from './quoteFormComponents/TextInput';
import ResidentCountryCheckbox from './quoteFormComponents/ResidentCountryCheckbox';
import type { DCXType } from '../../../types/DCXTypes';
import { getArrayListFromValues } from '../../../utils';
import { allYears, pastYears } from '../../../components/CustomDatePicker';
import { changeQuoteFormFront } from '../../../state/quoteForm/quoteFormActions';

type Props = {
  quoteData: QuoteFormType,
  countryList: Object[],
  save: Function,
  handleSubmit: Function,
  acceptedLob: Function,
  errorMsg: Function[],
  LOB: string,
  match: Object,
  DCX: DCXType,
};

const calculateRelationshipStatusCode = (dcxValues, quoteFormValues) => {
  if (!dcxValues || !quoteFormValues || typeof quoteFormValues !== 'object') {
    return null;
  }

  return dcxValues
    .filter((status) => {
      return status.name && quoteFormValues[status.name] === true;
    })
    .map(status => status.code);
};
const cleanRelationshipLabels = (labels: Object, dcxValues: Object, chosen: string[]) => {
  if (!dcxValues || !labels || !chosen) {
    return null;
  }

  const cleanedLabels = {};
  for (const dcxValue of dcxValues) {
    if (labels[dcxValue.name] && chosen[dcxValue.name]) cleanedLabels[dcxValue.code] = labels[dcxValue.name];
  }
  return cleanedLabels;
};

const pickGeozonesTranslation = (dcxGeozones, geozoneTranslations) => {
  return !!dcxGeozones && dcxGeozones.reduce((acc, dcxGeo) => {
    if (geozoneTranslations.find(geoTrans => geoTrans.code === dcxGeo)) {
      const obj = !!geozoneTranslations && geozoneTranslations.find(geo => geo.code === dcxGeo) || ({ translation: dcxGeo });
      acc[dcxGeo] = obj.translation;
    }
    return acc;
  }, {});
};


const CheckDCXData = (dcxAgebands, agebands) => {
  let result = true;
  const notMatchDCXData = [];
  const matchDCXData = [];

  //* Check current agebands value is exist in dcx data or not
  agebands.map((item) => {
    const dcxItemData = dcxAgebands.filter(dcxItem => dcxItem.code === item).map(({ code }) => ({ code }));
    if (dcxItemData.length === 0) {
      result = false;
      notMatchDCXData.push(item);
    } else {
      matchDCXData.push(item);
    }
  });
  // console.clear();
  // console.log(`CheckDCXData : ${result}`);
  // console.log(`notMatchDCXData : ${JSON.stringify(notMatchDCXData)}`);
  // console.log(`matchDCXData : ${JSON.stringify(matchDCXData)}`);
  return matchDCXData;
};

const orderAgeBand = (dcxAgebands, agebands) => {
  // console.clear();
  // console.log(`agebands : ${JSON.stringify(agebands)}`);
  // console.log(`agebands Count : ${agebands.length}`);
  // console.log(`dcxAgebands : ${JSON.stringify(dcxAgebands)}`);
  // console.log(`dcxAgebands Count : ${dcxAgebands.length}`);

  //* Validate all items in agebands. Remove data automatically if data in agabands does not found with dcxAgeBands
  const validAgebands = CheckDCXData(dcxAgebands, agebands);

  //* Sort agebands order by mapping with age range(min) in dcxAgebands
  const orderedAgeBand = validAgebands.map(ageband => dcxAgebands.filter(dcxAB => dcxAB.code === ageband)[0]).sort((a, b) => (a.range.min < b.range.min ? 1 : -1))
    .map(sorted => sorted.code).join('/');

  // console.log(`orderedAgeBand : ${JSON.stringify(orderedAgeBand)}`);
  // console.log('-----------------------------------------------------------------------------------');
  return orderedAgeBand;
};


let QuoteForm = (props: Props) => {
  const relationshipStatusCode = calculateRelationshipStatusCode(
    props.DCX.relationshipStatus,
    props.quoteData.additionnalQuoteData.relationshipStatus,
  );
  const relationshipLabels = cleanRelationshipLabels(
    props.quoteData.quoteLabelTranslation.relationshipStatusLabels,
    props.DCX.relationshipStatus,
    props.quoteData.additionnalQuoteData.relationshipStatus,
  );
  const geozones = pickGeozonesTranslation(props.DCX.geozones, props.quoteData.quoteLabelTranslation.geozones);

  const isAgeBands = (props.quoteData.mainQuoteData.travellers
    && props.quoteData.mainQuoteData.travellers.number) !== true;
  const isAgeBandsAdditionnal = props.quoteData.additionnalQuoteData.travellers
    && props.quoteData.additionnalQuoteData.travellers.number !== true;
  const travellersValues = getArrayListFromValues(isAgeBands ? 0 : 1, 50);

  return (
    <form onSubmit={props.handleSubmit(props.save)}>
      {
        props.quoteData.mainQuoteData.tripType === 'singleAnnual' && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING]) &&
        <Field
          component={ContainerRadio}
          name="tripType"
          label={props.quoteData.quoteLabelTranslation.tripType ? props.quoteData.quoteLabelTranslation.tripType : I18n.t('quoteForm.labelTranslation.tripType')}
          values={[{ value: 'single', label: props.quoteData.quoteLabelTranslation.singleTrip }, {
            value: 'annual',
            label: props.quoteData.quoteLabelTranslation.annualTrip,
          }]}
          validate={[props.errorMsg.tripTypeErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.residenceCountry === 'multi' &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectResidenceCountry || I18n.t('placeholder.residenceCountry')}
          name="residenceCountry"
          label={props.quoteData.quoteLabelTranslation.residenceCountry ? props.quoteData.quoteLabelTranslation.residenceCountry : I18n.t('quoteForm.labelTranslation.residenceCountry')}
          statusLabels={props.countryList.reduce((map, country) => {
            map[country.countryCode] = country.countryName;
            return map;
          }, {})}
          errorMsg={props.quoteData.quoteLabelTranslation.residenceCountryErrorMsg}
          validate={[props.errorMsg.residenceCountryErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.departureCountry === 'multi' && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.CAR_RENTAL]) &&
        <Field
          name="departureCountry"
          label={props.quoteData.quoteLabelTranslation.departureCountry ? props.quoteData.quoteLabelTranslation.departureCountry : I18n.t('quoteForm.labelTranslation.departureCountry')}
          statusLabels={props.countryList.reduce((map, country) => {
            map[country.countryCode] = country.countryName;
            return map;
          }, {})}
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDepartureCountry || I18n.t('placeholder.departureCountry')}
          validate={[props.errorMsg.departureCountryErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.departureCountry === 'one' && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.CAR_RENTAL]) &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDepartureCountry || I18n.t('placeholder.departureCountry')}
          name="departureCountry"
          statusLabels={props.countryList.filter(country => country.countryCode === props.match.params.countryCode).reduce((map, country) => {
            map[country.countryCode] = country.countryName;
            return map;
          }, {})}
          disabled
          label={props.quoteData.quoteLabelTranslation.departureCountry ? props.quoteData.quoteLabelTranslation.departureCountry : I18n.t('quoteForm.labelTranslation.departureCountry')}
          errorMsg={props.quoteData.quoteLabelTranslation.departureCountryErrorMsg}
          validate={[props.errorMsg.departureCountryErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.AIRLINES, LOBS.LODGING]) &&
        props.quoteData.mainQuoteData.destination === 'countries' &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDestination || I18n.t('placeholder.destination')}
          name="destination"
          label={props.quoteData.quoteLabelTranslation.destination ? props.quoteData.quoteLabelTranslation.destination : I18n.t('quoteForm.labelTranslation.destination')}
          statusLabels={props.countryList.reduce((map, country) => {
            map[country.countryCode] = country.countryName;
            return map;
          }, {})}
          validate={[props.errorMsg.destinationErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.AIRLINES, LOBS.LODGING]) &&
        props.quoteData.mainQuoteData.destination !== 'countries' &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDestination || I18n.t('placeholder.destination')}
          name="destination"
          label={props.quoteData.quoteLabelTranslation.destination ? props.quoteData.quoteLabelTranslation.destination : I18n.t('quoteForm.labelTranslation.destination')}
          statusLabels={pickGeozonesTranslation(props.DCX.geozones, props.quoteData.quoteLabelTranslation.geozones)}
          list={props.DCX.geozones || []}
          validate={[props.errorMsg.destinationErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.destination && props.acceptedLob([LOBS.TRAIN, LOBS.CAR_RENTAL]) &&
        props.quoteData.additionnalQuoteData.destination === 'countries' &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDestination || I18n.t('placeholder.destination')}
          name="destination"
          label={props.quoteData.quoteLabelTranslation.destination ? props.quoteData.quoteLabelTranslation.destination : I18n.t('quoteForm.labelTranslation.destination')}
          statusLabels={props.countryList.reduce((map, country) => {
            map[country.countryCode] = country.countryName;
            return map;
          }, {})}
          validate={[props.errorMsg.destinationErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.destination && props.acceptedLob([LOBS.TRAIN, LOBS.CAR_RENTAL]) &&
        props.quoteData.additionnalQuoteData.destination !== 'countries' &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectDestination || I18n.t('placeholder.destination')}
          name="destination"
          label={props.quoteData.quoteLabelTranslation.destination ? props.quoteData.quoteLabelTranslation.destination : I18n.t('quoteForm.labelTranslation.destination')}
          statusLabels={geozones}
          list={props.DCX.geozones || []}
          validate={[props.errorMsg.destinationErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.CAR_RENTAL]) && props.tripType !== 'annual' &&
        <Field
          component={DatesOfTrip}
          name="dateRange"
          triptype={props.tripType}
          startTime={props.quoteData.mainQuoteData.startDate === 'dateTime'}
          endTime={props.quoteData.mainQuoteData.endDate === 'dateTime'}
          startDate={props.quoteData.quoteLabelTranslation.startDate ? props.quoteData.quoteLabelTranslation.startDate : I18n.t('quoteForm.labelTranslation.startDate')}
          endDate={props.quoteData.quoteLabelTranslation.endDate ? props.quoteData.quoteLabelTranslation.endDate : I18n.t('quoteForm.labelTranslation.endDate')}
          annualStartDate={props.quoteData.quoteLabelTranslation.annualStartDate ? props.quoteData.quoteLabelTranslation.annualStartDate : I18n.t('quoteForm.labelTranslation.annualStartDate')}
          annualEndDate={props.quoteData.quoteLabelTranslation.annualEndDate ? props.quoteData.quoteLabelTranslation.annualEndDate : I18n.t('quoteForm.labelTranslation.annualEndDate')}
          quoteData={props.quoteData}
          LOB={props.LOB}
          validate={[props.errorMsg.dateRangeErrorMsg]}
          normalize={val => ({ startDate: val.startDate || moment(), endDate: val.endDate || moment() })}
        />
      }
      {
        //! ECOMDEV-39041 - Fix bug trip type not change when user select annual type on admin-ui
        props.acceptedLob([LOBS.AIRLINES]) && props.tripType === 'annual' &&
        <Field
          component={AnnualDatesOfTrip}
          name="dateRange"
          triptype={props.tripType}
          startTime={props.quoteData.mainQuoteData.startDate === 'dateTime'}
          endTime={props.quoteData.mainQuoteData.endDate === 'dateTime'}
          startDate={props.quoteData.quoteLabelTranslation.startDate ? props.quoteData.quoteLabelTranslation.annualStartDate : I18n.t('quoteForm.mainQuoteData.startDate')}
          endDate={props.quoteData.quoteLabelTranslation.endDate ? props.quoteData.quoteLabelTranslation.annualEndDate : I18n.t('quoteForm.labelTranslation.endDate')}
          quoteData={props.quoteData}
          LOB={props.LOB}
          validate={[props.errorMsg.dateRangeErrorMsg]}
          normalize={val => ({ startDate: val.startDate || moment(), endDate: val.endDate || moment() })}
        />
      }
      {
        props.quoteData.additionnalQuoteData.bookingDate && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.CAR_RENTAL]) &&
        <Field
          component={DatePickerQuoteForm}
          years={pastYears}
          name="bookingDate"
          date={props.quoteData.quoteLabelTranslation.bookingDate ? props.quoteData.quoteLabelTranslation.bookingDate : I18n.t('quoteForm.labelTranslation.bookingDate')}
          label={props.quoteData.quoteLabelTranslation.bookingDate ? props.quoteData.quoteLabelTranslation.bookingDate : I18n.t('quoteForm.labelTranslation.bookingDate')}
          validate={[props.errorMsg.bookingDateErrorMsg]}
          normalize={val => val || moment()}
        />
      }
      {
        props.quoteData.additionnalQuoteData.rentalObject && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN]) &&
        <Field
          component={ContainerRadio}
          name="rentalObject"
          label={props.quoteData.quoteLabelTranslation.rentalObject ? props.quoteData.quoteLabelTranslation.rentalObject : I18n.t('quoteForm.labelTranslation.rentalObject')}
          values={[{ value: 'true', label: props.quoteData.quoteLabelTranslation.rentalObjectYes }, {
            value: 'false',
            label: props.quoteData.quoteLabelTranslation.rentalObjectNo,
          }]}
          validate={[props.errorMsg.rentalObjectErrorMsg]}
        />
      }
      {
        relationshipStatusCode && relationshipStatusCode.length > 0 &&
        props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN]) &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.relationshipDescription || I18n.t('placeholder.relationShipStatus')}
          name="relationshipStatus"
          label={props.quoteData.quoteLabelTranslation.relationshipStatus ? props.quoteData.quoteLabelTranslation.relationshipStatus : I18n.t('quoteForm.labelTranslation.relationshipStatus')}
          list={relationshipStatusCode}
          statusLabels={relationshipLabels}
          validate={[props.errorMsg.relationshipStatusErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING]) &&
        <Field
          component={NumberOfTravellers}
          name="travellers"
          changeForm={(fieldName, values) => {
            props.changeQuoteFormFront(fieldName, values);
          }}
          // eslint-disable-next-line no-nested-ternary
          travellers={props.quoteData.mainQuoteData.travellers ? !isAgeBands ? 'number' : orderAgeBand(props.DCX.agebands, Object.keys(props.quoteData.mainQuoteData.travellers)) : ''}
          quoteData={props.quoteData}
          label={props.quoteData.quoteLabelTranslation.numberOfPassenger ? props.quoteData.quoteLabelTranslation.numberOfPassenger : I18n.t('quoteForm.labelTranslation.numberOfPassenger')}
          agebandLabels={props.quoteData.quoteLabelTranslation.agebandLabels ? JSON.parse(props.quoteData.quoteLabelTranslation.agebandLabels) : {}}
          list={travellersValues}
          validate={props.errorMsg.numberOfTravellersErrorMsg}
          travellersWording={props.quoteData.quoteSummaryTranslation.travellers}
        />
      }
      {
        (!isAgeBands || Object.keys(props.quoteData.additionnalQuoteData.travellers).length !== 0) && props.acceptedLob([LOBS.CAR_RENTAL]) &&
        <Field
          name="travellers"
          quoteData={props.quoteData}
          label={props.quoteData.quoteLabelTranslation.numberOfPassenger ? props.quoteData.quoteLabelTranslation.numberOfPassenger : I18n.t('quoteForm.labelTranslation.numberOfPassenger')}
          agebandLabels={props.quoteData.quoteLabelTranslation.agebandLabels ? JSON.parse(props.quoteData.quoteLabelTranslation.agebandLabels) : {}}
          list={travellersValues}
          component={NumberOfTravellers}
          // eslint-disable-next-line no-nested-ternary
          travellers={props.quoteData.additionnalQuoteData.travellers ? !isAgeBandsAdditionnal ? 'number' : orderAgeBand(props.DCX.agebands, Object.keys(props.quoteData.additionnalQuoteData.travellers)) : ''}
          validate={props.errorMsg.numberOfTravellersErrorMsg}
        />
      }
      {
        props.acceptedLob([LOBS.TICKETING]) &&
        <Field
          component={DatePickerQuoteForm}
          years={allYears}
          name="eventDate"
          startDate={props.quoteData.quoteLabelTranslation.eventDate ? props.quoteData.quoteLabelTranslation.eventDate : I18n.t('quoteForm.labelTranslation.eventDate')}
          label={props.quoteData.quoteLabelTranslation.eventDate ? props.quoteData.quoteLabelTranslation.eventDate : I18n.t('quoteForm.labelTranslation.eventDate')}
          normalize={val => val || moment()}
        />
      }
      {
        props.acceptedLob([LOBS.TICKETING]) &&
        <Field
          component={TextInput}
          name="eventName"
          label={props.quoteData.quoteLabelTranslation.eventName ? props.quoteData.quoteLabelTranslation.eventName : I18n.t('quoteForm.labelTranslation.eventName')}
        />
      }
      {
        props.acceptedLob([LOBS.TICKETING]) &&
        <Field
          component={SelectFieldPreview}
          placeholder={props.quoteData.quoteLabelTranslation.selectNumber || I18n.t('placeholder.number')}
          name="numberOfTickets"
          label={props.quoteData.quoteLabelTranslation.numberOfTickets ? props.quoteData.quoteLabelTranslation.numberOfTickets : I18n.t('quoteForm.labelTranslation.numberOfTickets')}
          list={getArrayListFromValues(1, 50)}
          validate={[props.errorMsg.numberOfTicketsErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.TICKETING]) &&
        <Field
          component={TextInput}
          name="bookingValue"
          label={props.quoteData.quoteLabelTranslation.bookingValue ? props.quoteData.quoteLabelTranslation.bookingValue : I18n.t('quoteForm.labelTranslation.bookingValue')}
        />
      }
      {
        props.acceptedLob([LOBS.TICKETING]) &&
        <Field
          component={DatePickerQuoteForm}
          years={allYears}
          name="bookingDate"
          date={props.quoteData.quoteLabelTranslation.bookingDate ? props.quoteData.quoteLabelTranslation.bookingDate : I18n.t('quoteForm.labelTranslation.bookingDate')}
          label={props.quoteData.quoteLabelTranslation.bookingDate ? props.quoteData.quoteLabelTranslation.bookingDate : I18n.t('quoteForm.labelTranslation.bookingDate')}
          validate={[props.errorMsg.bookingDateErrorMsg]}
          normalize={val => val || moment()}
        />
      }
      {
        props.quoteData.additionnalQuoteData.carHireReference && props.acceptedLob([LOBS.CAR_RENTAL]) &&
        <Field
          name="carHireReference"
          component={TextInput}
          placeholder={props.quoteData.quoteLabelTranslation.selectCarHireReference || I18n.t('placeholder.selectCarHireReference')}
          validate={[props.errorMsg.carHireReferenceErrorMsg]}
          label={props.quoteData.quoteLabelTranslation.carHireReference || I18n.t('quoteForm.labelTranslation.carHireReference')}
        />
      }
      {
        props.acceptedLob([LOBS.LEGAL]) &&
        <Field
          component={ContainerRadio}
          name="employeeTravelDuration"
          label={props.quoteData.quoteLabelTranslation.employeeTravelDuration ? props.quoteData.quoteLabelTranslation.employeeTravelDuration : I18n.t('quoteForm.labelTranslation.employeeTravelDuration')}
          values={[{
            label: `${props.quoteData.quoteLabelTranslation.lessThan} ${props.quoteData.mainQuoteData.maximumDays || '...'}`,
            value: props.quoteData.mainQuoteData.maximumDays || '1',
          }, {
            label: `${props.quoteData.quoteLabelTranslation.moreThan} ${props.quoteData.mainQuoteData.minimumDays || '...'}`,
            value: props.quoteData.mainQuoteData.minimumDays || '0',
          }]}
          validate={[props.errorMsg.employeeTravelDurationErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.LEGAL]) &&
        <Field
          component={TextInput}
          name="numberTravellingDays"
          placeholder={props.quoteData.quoteLabelTranslation.selectNumberOfTravellingDaysPerYear || I18n.t('placeholder.selectNumberOfTravellingDaysPerYear')}
          label={props.quoteData.quoteLabelTranslation.numberOfTravellingDaysPerYear ? props.quoteData.quoteLabelTranslation.numberOfTravellingDaysPerYear : I18n.t('quoteForm.labelTranslation.numberOfTravellingDaysPerYear')}
          validate={[props.errorMsg.numberOfTravellingDaysPerYearErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.LEGAL]) &&
        <Field
          component={TextInput}
          name="numberOfPassenger"
          label={props.quoteData.quoteLabelTranslation.numberOfTravellingEmployee ? props.quoteData.quoteLabelTranslation.numberOfTravellingEmployee : I18n.t('quoteForm.labelTranslation.numberOfTravellingEmployee')}
          validate={[props.errorMsg.numberOfTravellersErrorMsg]}
        />
      }
      {
        props.acceptedLob([LOBS.LEGAL]) &&
        <Field
          component={DatePickerQuoteForm}
          years={allYears}
          name="dateRange.startDate"
          startDate={props.quoteData.quoteLabelTranslation.insuranceStartDate ? props.quoteData.quoteLabelTranslation.startDate : I18n.t('quoteForm.mainForm.startDate')}
          label={props.quoteData.quoteLabelTranslation.insuranceStartDate ? props.quoteData.quoteLabelTranslation.insuranceStartDate : I18n.t('quoteForm.labelTranslation.insuranceStartDate')}
          validate={[props.errorMsg.insuranceStartDateErrorMsg]}
          normalize={val => val || moment()}
        />
      }
      {
        props.quoteData.additionnalQuoteData.tripCost && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.TICKETING]) &&
        <Field
          name="tripCost"
          placeholder={props.quoteData.quoteLabelTranslation.selectPrice || I18n.t('placeholder.selectPrice')}
          type="number"
          min="0"
          step="0.01"
          label={props.quoteData.quoteLabelTranslation.price ? props.quoteData.quoteLabelTranslation.price : I18n.t('quoteForm.quoteLabelTranslation.price')}
          component={TextInput}
          validate={[props.errorMsg.priceErrorMsg]}
        />
      }
      {
        props.quoteData.additionnalQuoteData.promoCode && props.acceptedLob([LOBS.AIRLINES, LOBS.TRAIN, LOBS.LODGING, LOBS.TICKETING]) &&
        <Field
          name="promoCode"
          placeholder={props.quoteData.quoteLabelTranslation.selectPromoCode || I18n.t('placeholder.selectPromoCode')}          
          label={props.quoteData.quoteLabelTranslation.promoCode ? props.quoteData.quoteLabelTranslation.promoCode : I18n.t('quoteForm.quoteLabelTranslation.promoCode')}
          component={TextInput}          
        />
      }
      {
        props.quoteData.additionnalQuoteData.residenceCountry === 'one' &&
        <Field
          name="residenceCountryConfirmation"
          label={`${props.quoteData.quoteLabelTranslation.residenceCountryConfirmation ? props.quoteData.quoteLabelTranslation.residenceCountryConfirmation : I18n.t('quoteForm.preview.residenceToDefine')}`}
          trueVal={props.match.params.countryCode}
          component={ResidentCountryCheckbox}
          validate={[props.errorMsg.residenceCountryErrorMsg]}
          errorMsg={props.quoteData.quoteLabelTranslation.residenceCountryErrorMsg}
        />
      }
      <button
        type="submit"
        className="wlf-button"
        style={{ background: props.quoteData.quoteStyle.callToAction }}
      >
        {props.quoteData.quoteLabelTranslation.getQuote ? props.quoteData.quoteLabelTranslation.getQuote : I18n.t('quoteForm.quoteLabelTranslation.getAQuote')}
      </button>
    </form>
  );
};

QuoteForm = reduxForm({
  form: 'quoteFormFront',
})(QuoteForm);

export default withRouter(connect(state => ({
  travellers: formValueSelector('quoteFormFront')(state, 'travellers'),
}), {
  changeQuoteFormFront,
})(QuoteForm));
