// @flow

import { addNotification as notify, removeNotifications } from 'reapop';

export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';

export function toggleConfirmation(isActive: boolean) {
  return {
    type: TOGGLE_CONFIRMATION,
    payload: {
      isActive,
    },
  };
}

export function toggleConfirmationWithModal(isActive: boolean) {
  return (dispatch: Function) => {
    dispatch(toggleConfirmation(isActive));
    dispatch(removeNotifications());
  };
}

function displayModal(
  title: string,
  message: string,
  onSuccess: Function,
  onRefuse: Function = () => {
  },
) {
  return (dispatch: Function) => {
    dispatch(toggleConfirmation(true));
    dispatch(notify({
      title,
      message,
      status: 'warning',
      dismissible: false,
      dismissAfter: 0,
      position: 'tc',
      buttons: [
        {
          name: 'Oui',
          primary: true,
          onClick: () => {
            dispatch(toggleConfirmation(false));
            onSuccess();
          },
        },
        {
          name: 'Non',
          primary: false,
          onClick: () => {
            dispatch(toggleConfirmation(false));
            dispatch(removeNotifications());
            onRefuse();
          },
        },
      ],
    }));
  };
}

export function displayModalAction(title: string, message: string, onSuccess: Function) {
  return (dispatch: Function) => {
    dispatch(displayModal(title, message, onSuccess));
  };
}
