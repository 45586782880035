const fr = {
  wsError: {
    INTERNAL_ERROR: 'Une erreur est survenue, veuillez réessayer ultèrieurement',
    AUTHENTIFICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse e-mail \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS: 'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS: 'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT: 'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    MESSAGE: '%{field0}',
    SITE_NOT_FOUND: 'Le site auquel vous souhaitez accéder n\'existe pas',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
};

export default fr;
