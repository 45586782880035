/* eslint-disable react/prop-types */
// @flow

import React, { Fragment } from 'react';
import moment from 'moment/moment';
import { SingleDatePicker } from 'react-dates';
import { withRouter } from 'react-router-dom';

type Props = {
  id:string,
  label: string,
  match: Object,
  history: Object,
  onDateChange: Function,
  onFocusChange: Function,
  focused: boolean,
  date: string,
  placeholder: string,
  isOutsideRange: boolean,
  showDefaultInputIcon: boolean,
  years: number[],
};

function generateAvailableYears(startYear, endYear): number[] {
  const cardYears = [];
  // eslint-disable-next-line no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    cardYears.push(i);
  }
  return cardYears;
}

const currentYear = moment().year();
export const pastYears = generateAvailableYears(1900, currentYear);
export const futureYears = generateAvailableYears(currentYear, currentYear + 10);
export const allYears = generateAvailableYears(1900, currentYear + 10);

function CustomDatePicker({ ...props }: Props) {
  moment.locale(props.match.params.langCode);

  return (
    <Fragment>
      <div className="label">{props.label}</div>
      <div className="container-calendar">
        <i className="fa fa-calendar" />
        <SingleDatePicker
          id={props.id}
          // withPortal
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          displayFormat="DD/MM/YYYY"
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
          (
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', width: '70%' }}>
              <select
                className="CalendarDay__select"
                style={{ margin: '0px' }}
                value={month.month()}
                onChange={(e) => {
                  onMonthSelect(month, e.target.value);
                }}
              >
                {moment.months().map((label, value) => (
                  // eslint-disable-next-line react/no-array-index-key                  
                  <option key={label} value={value}>{label}</option>
                ))}       
              </select>
              <select
                className="CalendarDay__select"
                style={{ margin: '0px', width: '80px' }}
                value={month.year()}
                onChange={(e) => {
                  onYearSelect(month, e.target.value);
                }}
              >
                {
                  // eslint-disable-next-line arrow-parens
                  props.years.map((year) =>
                    (<option key={year} value={year}>{year}</option>))                    
                }
              </select>
            </div>)
          }
          onDateChange={props.onDateChange}
          onFocusChange={props.onFocusChange}
          showDefaultInputIcon={props.showDefaultInputIcon}
          isOutsideRange={props.isOutsideRange}
          date={props.date}
          focused={props.focused}
          placeholder={props.placeholder}
          readOnly          
          disabled={props.disabled}
        />
      </div>
    </Fragment>
  );
}

export default withRouter(CustomDatePicker);
