/* eslint-disable no-plusplus */
export function arrayToObject(arr) {
  if (typeof arr === 'object') {
    return arr.reduce((acc, current) => {
      acc[current] = true;
      return acc;
    }, {});
  }
  return {};
}

// use to filter array values to get only non null values
// => [1,undefined,null,2,3].filter(nonNull) => [1, 2, 3]
export function nonNull(value) {
  return value;
}

export const convertHex = (hex, opacity) => {
  const hexCopy = hex.replace('#', '');
  const r = parseInt(hexCopy.substring(0, 2), 16);
  const g = parseInt(hexCopy.substring(2, 4), 16);
  const b = parseInt(hexCopy.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
};

export function getArrayListFromValues(min, max) {
  const arr = [];
  for (let i = min; i < max + 1; i++) {
    arr.push(i);
  }
  return arr;
}
