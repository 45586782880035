/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-operators */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { format } from 'react-string-format';
import moment from 'moment';
import QuoteFormContainer from '../homeSite/component/QuoteFormContainer';
import { initializeQuoteFormFront, saveQuotationRS } from '../../state/quoteForm/quoteFormActions';
import ProductOffer from './component/ProductOffer';
import { buildFrontUrl } from '../../routes';
import Loader from '../../components/Loader';
import { HighlightedOffer } from './component/HighlightedOffer';
import { getEnvironment } from '../../helper/helper';

type Props = {
  stepsNumber: Number,
  breadcrumb: {},
  quotationIptTaxesLabel: ?string,
  match: {
    params: {
      partnerCode: String,
      countryCode: String,
      langCode: String,
    }
  },
  initializeQuoteFormFront: Function,
  saveQuotationRS: Function,
  history: Object,
  nextStep: string,
  showTaxes: boolean,
  quoteForm: Object,
}

type State = {
  data: Object,
}

class ProductQuote extends React.Component<Props, State> {
  state = {
    // eslint-disable-next-line react/no-unused-state
    data: {
      subTitle: '',
    },
  };

  // Create a ref for product quote element
  productInfoRef = React.createRef(); 

  getDateDiff = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    const result = moment(newStartDate).diff(newEndDate, 'days');
    return result + 1;
  };

  componentDidMount() {
    const quoteFormFront = JSON.parse(localStorage.getItem('quoteFormFront'));
    const quotationRS = JSON.parse(localStorage.getItem('quotationRS'));
    const quotationRQ = JSON.parse(localStorage.getItem('quotationRQ'));
    this.props.initializeQuoteFormFront({
      ...quoteFormFront,
      dateRange: {
        startDate: quoteFormFront && quoteFormFront.dateRange && moment(quoteFormFront.dateRange.startDate) || '',
        endDate: quoteFormFront && quoteFormFront.dateRange && moment(quoteFormFront.dateRange.endDate) || '',
      },
    }, this.props.match.params.countryCode);
    if (quotationRS) {
      this.props.saveQuotationRS(quotationRS);
    }

    //! ECOMDEV-40085 - Adobe script implementation
    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);
    // Page Title
    if (this.props.breadcrumb.quoteStep1 === null || this.props.breadcrumb.quoteStep1 === undefined) {
      document.title = 'Product Quote';
    } else {
      document.title = this.props.breadcrumb.quoteStep1;
    }
    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Product Quote',
          pageType: 'Product Quote',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
      quote: {
        coverage: {
          daysBeforeCoverageStart: this.getDateDiff(quoteFormFront.dateRange.startDate, quoteFormFront.bookingDate), // booking date - start date
          duration: this.getDateDiff(quoteFormFront.dateRange.endDate, quoteFormFront.dateRange.startDate), // end date - start date
          endDate: quotationRQ.travels[0].destinations[0].destinationDate,
          startDate: quotationRQ.travels[0].originDate,
          objects: {
            coverageRegion: quoteFormFront.destination, // BU or Region
            coverageOptions: quotationRQ.travels[0].travelType, // one-way-trip
          },
          persons: {
            insuredPersons: quoteFormFront.travellers, // age band - "infant:0|child:0|youth:0|adult:0|senior:0|oldSenior:0" or Number of passenger - "1"
            numberOfPersons: quoteFormFront.numberOfTravellers, // Recheck case passenger > 1 & age band
          },
        },
        policyHolder: {
          residenceCountry: quotationRQ.travels[0].residence.countryCode,
        },
        quoteDetails: {
          date: quotationRQ.travels[0].bookingDate,
          insuredCost: quotationRQ.travels[0].totalValue.amount ? quotationRQ.travels[0].totalValue.amount : 0, // Trip Cost - Default 0 if no trip cost on quote form
          primaryCategory: quotationRQ.travels[0].travelType,
        },
      },
    };
    // eslint-disable-next-line no-underscore-dangle
    window._satellite.track('getAQuote');

    // console.log('-- Product Quote --');
    // console.log(window.digitalData);

    // Set focus on initial component mount
    if (this.productInfoRef.current) {
      this.productInfoRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Only set focus on initial load and not on state updates
    if (!this.state.initialFocusSet && this.productInfoRef.current) {
      this.productInfoRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  render() {
    if (!localStorage.getItem('quoteFormFront') || !JSON.parse(localStorage.getItem('quoteFormFront')) == {}) {
      window.location.href = `${window.location.origin}${buildFrontUrl(this.props.match.params, '')}`;
    }
    if (!this.props.quoteForm || !this.props.quotation) {
      return false;
    }

    const IsPromoEnable =  this.props.quoteForm.additionnalQuoteData.promoCode;  
    const pageURLElements = window.location.href.split('/');  
    const currentlanguage = pageURLElements[6];

    const quotationRS = JSON.parse(localStorage.getItem('quotationRS'));
    const contractList = quotationRS && quotationRS.contract && quotationRS.contract.contractList;
    const contractListWithoutHighlight = contractList && contractList.filter(contract => !contract.productOffering.highlightedOffer)
      .sort((a, b) => parseInt(a.categorySortingNumber, 10) - parseInt(b.categorySortingNumber, 10));
    if (contractList && contractList.filter(contract => contract.productOffering.highlightedOffer)[0]) {
      contractListWithoutHighlight.unshift(contractList.filter(contract => contract.productOffering.highlightedOffer)[0]);
    }
    const contractListFinal = contractListWithoutHighlight;

    return (
      <div className="homeSite">
        <div id="body-site" className="body-site">
          <div className="body-content body-content-top">
            <div className="flex-display full-start margin base-content">
              <QuoteFormContainer
                LOB={this.props.data.lineOfBusiness}
                quoteForm={this.props.quoteForm}
              />
              <div
                style={{ borderTop: `4px solid ${this.props.data.mainThemeColor}` }}
                className="booking-path-container up full"
              >
                <div className="steps-container">
                  {
                    this.props.breadcrumb && Object.keys(this.props.breadcrumb).filter((s, i) => i < this.props.stepsNumber).map((step, i) => (
                      <div className="step-container" key={step}>
                        <div
                          style={{
                            borderColor: this.props.data.mainThemeColor,
                            color: i !== 0 ? this.props.data.mainThemeColor : '',
                            background: i === 0 ? this.props.data.mainThemeColor : '',
                          }}
                          className="step-square"
                        >
                          <span>{i + 1}</span>
                        </div>
                        <div
                          style={{ color: this.props.data.mainThemeColor }}
                          className="step-title"
                        >{this.props.breadcrumb[step]}
                        </div>
                      </div>
                    ))
                  }
                </div>                
                <div id="product-info" ref={this.productInfoRef} tabIndex={-1} className="booking-path-content" >
                  {
                    this.props.submitting &&
                    <div className="layer-loading">
                      <Loader />
                    </div>
                  }
                  {
                    (!quotationRS || !quotationRS.contract || !contractList || !contractListFinal) &&
                    <div
                      // eslint-disable-next-line react/jsx-closing-tag-location
                      className="no-product"
                    >{this.props.quotation.errorNoProduct || I18n.t('bookingPathForm.quotation.noProductErrorMsg')}
                    </div>
                  }
                  {
                    contractListFinal &&
                      contractListFinal.map((contract, index) => {
                        if (contract.productOffering.highlightedOffer) {
                          return (
                            <HighlightedOffer
                              description={this.props.quotation.highlightedOfferDescription}
                              title={this.props.quotation.highlightedOfferTitle}
                              color={this.props.data.highlightedOfferColorText}
                              background={this.props.data.highlightedOfferColorBackground}
                            >
                              <ProductOffer
                                key={contract.productOfferingSign}
                                contract={contract}
                                quotation={this.props.quotation}
                                history={this.props.history}
                                match={this.props.match}
                                quoteFormFront={JSON.parse(localStorage.getItem('quoteFormFront'))}
                                nextStep={this.props.nextStep}
                                color={this.props.data.mainThemeColor}
                                quotationIptTaxesLabel={this.props.quotationIptTaxesLabel}
                                showTaxes={this.props.showTaxes}
                                IsPromoEnable={IsPromoEnable}
                                language={currentlanguage}
                                index={index}
                              />
                            </HighlightedOffer>
                          );
                        // eslint-disable-next-line no-else-return
                        } else {
                          return (
                            <ProductOffer
                              key={contract.productOfferingSign}
                              contract={contract}
                              quotation={this.props.quotation}
                              history={this.props.history}
                              match={this.props.match}
                              quoteFormFront={JSON.parse(localStorage.getItem('quoteFormFront'))}
                              nextStep={this.props.nextStep}
                              color={this.props.data.mainThemeColor}
                              quotationIptTaxesLabel={this.props.quotationIptTaxesLabel}
                              showTaxes={this.props.showTaxes}
                              IsPromoEnable={IsPromoEnable}
                              language={currentlanguage}   
                              index={index}                           
                            />
                          );
                        }
                      })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    submitting: state.quoteForm.submitting,
    quotationRS: state.quoteForm.quotationRS,
  }),
  {
    initializeQuoteFormFront,
    saveQuotationRS,
  },
)(ProductQuote));
