/* eslint-disable max-len */
// @flow

import {
  STORE_QUOTE_FORM,
  STORE_COUNTRIES,
  STORE_QUOTATION_RS, SUBMITTING,
} from './quoteFormActions';

const initialState = {
  submitting: null,
  quoteForm: null,
  countries: [],
  quotationRS: null,
};

export default function quoteFormReducer(state: any = initialState, action: { type: string, payload: any }) {
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        submitting: action.payload.submitting,
      };
    }
    case STORE_QUOTE_FORM: {
      return {
        ...state,
        quoteForm: action.payload.quoteForm,
      };
    }
    case STORE_COUNTRIES: {
      return {
        ...state,
        countries: action.payload.countries,
      };
    }
    case STORE_QUOTATION_RS: {
      return {
        ...state,
        quotationRS: action.payload.quotationRS,
      };
    }
    default:
      return state;
  }
}

