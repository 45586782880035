/* eslint-disable no-extra-boolean-cast */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-empty */
// @flow

import { initialize, change } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { notifyError } from '../../network/network';
import referentialApi from '../../network/api/referentialApi';
import { arrayToObject } from '../../utils';
import type { QuotationRS } from '../../types/QuotationTypes';

export const STORE_QUOTE_FORM = 'STORE_QUOTE_FORM';
export const SUBMITTING = 'SUBMITTING';
export const STORE_COUNTRIES = 'STORE_COUNTRIES';
export const STORE_QUOTATION_RS = 'STORE_QUOTATION_RS';
export const initialQuoteForm = {
  mainForm: {
    tripType: '',
    residenceCountry: '',
    destination: '',
    travellers: '',
    startDate: '',
    endDate: '',
    minimumDays: '',
    maximumDays: '',
  },
  additionnalFields: {
    tripCost: true,
    bookingDate: true,
    rentalObject: false,
    relationshipStatus: '',
    eventName: false,
    carHireReference: false,
    promoCode: false,
  },
  labelTranslation: {
    titleLine1: '',
    titleLine2: '',
    tripType: '',
    singleTrip: '',
    annualTrip: '',
    residenceCountry: '',
    residenceCountryErrorMsg: '',
    selectResidenceCountry: '',
    destination: '',
    destinationErrorMsg: '',
    selectDestination: '',
    startDate: '',
    startDateMsg: '',
    endDate: '',
    endDateMsg: '',
    annualStartDate: '',
    annualStartDateMsg: '',
    annualEndDate: '',
    annualEndDateMsg: '',
    numberOfPassenger: '',
    numberOfPassengerErrorMsg: '',
    adult: '',
    adultAgeBandLabel: '',
    child: '',
    childAgeBandLabel: '',
    infant: '',
    infantAgeBandLabel: '',
    relationShipStatus: '',
    relationshipStatusLabels: '',
    relationshipDescription: '',
    relationshipStatusErrorMsg: '',
    family: '',
    single: '',
    couple: '',
    group: '',
    price: '',
    priceErrorMsg: '',
    promoCode: '',
    selectPromoCode: '',
    getQuote: '',
  },
  summary: {
    summary: '',
    insuranceCover: '',
    startDate: '',
    endDate: '',
    annualStartDate: '',
    annualEndDate: '',
    productName: '',
    travelFromCountry: '',
    travelTo: '',
    currencyDetail: '',
    traveller: '',
    travellers: '',
    detailMessage: '',
    termsAndConditions: '',
    totalPrice: '',
    promoCode: '',
  },
  colorPicker: {
    fontColorTitle: '#000000',
    callToAction: '#000000',
    detailSummaryTitleColor: '#000000',
    detailSummaryPanelColor: '#000000',
  },
};

export function storeCountries(countries: Object[]) {
  return {
    type: STORE_COUNTRIES,
    payload: {
      countries,
    },
  };
}
export function toggleSubmitting(submitting: boolean) {
  return {
    type: SUBMITTING,
    payload: {
      submitting,
    },
  };
}

export function loadCountriesIfNeeded(lang: string) {
  return (dispatch: Function, getState: Function) => {
    if (getState().quoteForm.countries.length === 0) {
      referentialApi.fetchCountries(lang)
        .then(resp => resp.json())
        .then(countries => {
          // eslint-disable-next-line semi
          dispatch(storeCountries(countries))
        })
        .catch(
          notifyError(dispatch, I18n.t('wsError.custom.SITE_FETCH_COUNTRY'))                
        )
    };
  };
}

export function toExploitableQuoteData(quoteForm) {
  return {
    ...quoteForm,
    mainQuoteData: {
      ...quoteForm.mainQuoteData,
      travellers: quoteForm.mainQuoteData ? arrayToObject(quoteForm.mainQuoteData.travellers ? quoteForm.mainQuoteData.travellers.split('/') : '') : '',
    },
    additionnalQuoteData: {
      ...quoteForm.additionnalQuoteData,
      relationshipStatus: quoteForm.additionnalQuoteData ? arrayToObject(quoteForm.additionnalQuoteData.relationshipStatus ? quoteForm.additionnalQuoteData.relationshipStatus.split('/') : '') : '',
      travellers: quoteForm.additionnalQuoteData ? arrayToObject(quoteForm.additionnalQuoteData.travellers ? quoteForm.additionnalQuoteData.travellers.split('/') : '') : '',
    },
    quoteLabelTranslation: {
      ...quoteForm.quoteLabelTranslation.quoteLabelTranslation,
      travellers: quoteForm.quoteLabelTranslation && quoteForm.quoteLabelTranslation.agebandLabels ?
        JSON.parse(quoteForm.quoteLabelTranslation.agebandLabels) : null,
      relationshipStatusLabels: quoteForm.quoteLabelTranslation && quoteForm.quoteLabelTranslation.quoteLabelTranslation.relationshipStatusLabels ?
        JSON.parse(quoteForm.quoteLabelTranslation.quoteLabelTranslation.relationshipStatusLabels) : null,
      geozones: quoteForm.quoteLabelTranslation.geozones,
    },
    quoteSummaryTranslation: { ...quoteForm.quoteSummaryTranslation },
    quoteStyle: { ...quoteForm.quoteStyle },
    dateRange: {
      startDate: quoteForm.startDate,
      endDate: quoteForm.endDate,
    },
  };
}

const dateOrNull = stringDate => (stringDate ? moment(stringDate, 'YYYYMMDD') : null);
const currentDate = moment();
const withDefaultCurrentDate = date => (date || currentDate);

export function initializeQuoteFormFront(quoteFormFront: Object, countryCode: string, quoteFormFrontFromQuery: Object) {
  let quoteFormFrontToInitialize = {
    ...(!!quoteFormFrontFromQuery ? quoteFormFrontFromQuery : {}),
    tripType: quoteFormFront.tripType || 'single',
    departureCountry: quoteFormFront.departureCountry || countryCode,
    residenceCountry: quoteFormFront.residenceCountry || countryCode,
    residenceCountryConfirmation: null,
    // SingleDateRange needs moment string
    bookingDate: withDefaultCurrentDate(dateOrNull(quoteFormFrontFromQuery && quoteFormFrontFromQuery.bookingDate)),
    eventDate: withDefaultCurrentDate(dateOrNull(quoteFormFrontFromQuery && quoteFormFrontFromQuery.eventDate)),
    ...quoteFormFront, // override if data in storage
  };
  const startDate = quoteFormFront && quoteFormFront.dateRange && quoteFormFront.dateRange.startDate || withDefaultCurrentDate(dateOrNull(quoteFormFrontFromQuery && quoteFormFrontFromQuery.startDate));
  const endDate = quoteFormFront && quoteFormFront.dateRange && quoteFormFront.dateRange.endDate || withDefaultCurrentDate(dateOrNull(quoteFormFrontFromQuery && quoteFormFrontFromQuery.endDate));
  quoteFormFrontToInitialize = {
    ...quoteFormFrontToInitialize,
    dateRange: {
      // DateRange needs moment object
      startDate,
      endDate,
    },
  };

  return (dispatch: Function) => {
    dispatch(initialize('quoteFormFront', quoteFormFrontToInitialize));
  };
}

export function storeQuotationRS(quotationRS: QuotationRS) {
  return {
    type: STORE_QUOTATION_RS,
    payload: {
      quotationRS,
    },
  };
}

export function saveQuotationRS(quotationRS: QuotationRS) {
  return (dispatch: Function) => {
    dispatch(storeQuotationRS(quotationRS));
  };
}

export const pickGeozonesTranslation = (dcxGeozones, geozoneTranslations) => {
  return !!dcxGeozones && dcxGeozones.reduce((acc, dcxGeo) => {
    if (geozoneTranslations.find(geoTrans => geoTrans.code === dcxGeo)) {
      const obj = !!geozoneTranslations && geozoneTranslations.find(geo => geo.code === dcxGeo) || ({ translation: dcxGeo });
      acc[dcxGeo] = obj.translation;
    }
    return acc;
  }, {});
};

export function changeQuoteFormFront(field: string, value: Object) {
  return (dispatch: Function) => {
    dispatch(change('quoteFormFront', field, value));
  };
}

export function notifyCustomQuotationError() {
  return (dispatch: Function) => {
    notifyError(dispatch, I18n.t('wsError.custom.QUOTATION'));
  };
}
