// @flow

import { ROOT_PAGE_ROUTE } from './const';

const parseQueryParams = () => {
  const validQueryParams = window.location.search.substring(1)
    .split('&')
    .filter(param => /^\w+[=][^&]+$/g.test(param))
    .join();
  return validQueryParams ?
    JSON.parse(`{"${
      decodeURI(validQueryParams)
        .replace(/"/g, '\\"')
        .replace(/,/g, '","')
        .replace(/=/g, '":"')}"}`) :
    {};
};
export const queryParams = parseQueryParams();
export const siteVersion = queryParams.version || ''; // default version is now defined in configuration file

export const buildFrontPath = (matchParams: Object, pagePath: string) =>
  `${ROOT_PAGE_ROUTE}/${matchParams.partnerCode}/${matchParams.countryCode}/${matchParams.langCode}${pagePath}`;

export const buildFrontUrl = (matchParams: Object, pagePath: string) => {
  const baseRoute = buildFrontPath(matchParams, pagePath);
  if (window.location.search) {
    return `${baseRoute}${window.location.search}`;
  }
  return baseRoute;
};

