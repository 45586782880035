/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-parens */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { format } from 'react-string-format';
import type { CreateSessionRS } from '../../../types/PaymentTypes';
import { required } from '../../../components/validation/ValidationFunctions';
import OnePayApi from '../../../network/api/onePayApi';
import eqsApi, { extractIpidDocumentUrl, extractTermAndConditionDocumentUrl } from '../../../network/api/eqsApi';
import { notifyError } from '../../../network/network';
import type { DCXType } from '../../../types/DCXTypes';
import CustomCheckbox from '../../homeSite/component/CustomCheckbox';
import { siteVersion } from '../../../routes';
import { buildCreationSessionRQ } from '../request/onePay';
import { buildCommitPolicy } from '../request/commitPolicy';
import { FORM_CONFIRMATION } from '../../../const';
import { getEnvironment } from '../../../helper/helper';
import { Store } from 'react-notifications-component';

type Props = {
  stepInfos: Object,
  breadcrumb: {},
  history: Object,
  match: {
    params: {
      partnerCode: String,
      countryCode: String,
      langCode: String,
    },
  },
  DCX: DCXType,
  dispatch: Function,
  valid: boolean,
  // useBroker: boolean,
}
type State = {
  url: string,
  displayError: boolean,
  errorPayment: string,
  displayCommitPolicyError: boolean,
}

const linkTargetToUrl = (target: string, termAndCondUrl: string, ipidUrl) => {
  if (target === 'termAndCondition') {
    return termAndCondUrl;
  }
  if (target === 'ipid') {
    return ipidUrl;
  }
  return target;
};

class PaymentDetails extends React.Component<Props, State> {
  state = {
    url: '',
    displayError: false,
    errorPayment: '',
    displayCommitPolicyError: false,
  };

  paymentSuccess = false;
  
  // Create a ref for payment details element
  paymentDetailsRef = React.createRef();

  componentWillUnmount(): void {
    window.removeEventListener('message', this.onePayResponseManagement);
  }

  onePayResponseManagement = (e: ResponsePayment) => {
    if (((e.data.paymentResponse && e.data.paymentResponse.status === 'Success') ||
      (e.data.directDebitResponse && e.data.directDebitStatus === 'Success'))
      && !this.paymentSuccess) {
      this.paymentSuccess = true;
      console.log('OnePay response', e.data);
      let commitPolicy = JSON.parse(localStorage.getItem('commitPolicy'));
      commitPolicy = buildCommitPolicy(e.data, commitPolicy);
      localStorage.setItem('commitPolicy', JSON.stringify(commitPolicy));
      eqsApi.commitPolicy(commitPolicy, siteVersion)
        .then(response => response.json())
        .then(commitPolicyRS => {
          if (commitPolicyRS.status === 'SUCCESS') {
            localStorage.setItem('commitPolicyRS', JSON.stringify(commitPolicyRS));
            this.props.history.push({
              pathname: FORM_CONFIRMATION,
              search: window.location.search,
              state: {
                policyNumber: commitPolicy.contractNumber,
              },
            });
            window.removeEventListener('message', this.onePayResponseManagement);
          } else {
            this.setState({ displayCommitPolicyError: true });
          }
        })
        .catch((ex) => {
          this.setState({ displayCommitPolicyError: true });     
          this.props.dispatch(notifyError)(ex);
        });
    } else if (e.data.paymentResponse && e.data.paymentResponse.status === 'Failure') {
      this.setState({
        displayError: true,
        errorPayment: e.data.paymentResponse ? e.data.paymentResponse.errorMessage : '',
      });
    } else if (e.data.directDebitResponse) {
      this.setState({
        displayError: true,
        errorPayment: e.data.directDebitStatus,
      });
    }
  };

  componentDidMount() {
    OnePayApi.getSession(buildCreationSessionRQ(), this.props.match.params.partnerCode, this.props.match.params.countryCode, this.props.match.params.langCode, siteVersion)
      .then(response => response.json())
      .then((session: CreateSessionRS) => {
        //* Check one pay open session result
        if (session.status === 'Failure') {
          console.log(`One pay open session status : ${session.status}`);
          console.log('One pay fail to open session. Please check one pay service');
          throw new Error(session.status);
        } else {
          console.log(`One pay open session status : ${session.status}`);
          console.log(`One pay successful to open session and we have got payment page url : ${session.paymentPageUrl}`);
          this.setState({ url: session.paymentPageUrl });
          window.addEventListener('message', this.onePayResponseManagement);
        }
      })
      .catch((e) => {            
        console.log("OnePay API Error: ", e.message); 
        Store.addNotification({
          title: "Error!",      
          message: I18n.t('wsError.custom.ONE_PAY'),
          type: "Danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 10000,
            onScreen: true
          }
        })                       
      });      

    //! ECOMDEV-40085 - Adobe script implementation
    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Page Title
    if (this.props.breadcrumb.quoteStep5 === null || this.props.breadcrumb.quoteStep5 === undefined) {
      document.title = this.props.breadcrumb.quoteStep3;
    } else {
      document.title = this.props.breadcrumb.quoteStep4;
    }

    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Payment Details',
          pageType: 'Payment Details',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
    };

    // console.log('-- Payment Details --');
    // console.log(window.digitalData);
    
    // Set focus on initial component mount
    if (this.paymentDetailsRef.current) {
      this.paymentDetailsRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Only set focus on initial load and not on state updates
    if (!this.state.initialFocusSet && this.paymentDetailsRef.current) {
      this.paymentDetailsRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  buildLabel = (index) => {
    const label = [];
    for (let i = 1; i <= 3; i++) {
      if (this.props.stepInfos[`paymentDetailsCheckbox${index}TextBefore${i}`]) {
        label.push(this.getLabel(index, i));
      }
    }
    return label;
  };

  buildLabel = (index) => {
    const label = [];
    for (let i = 1; i <= 3; i++) {
      if (this.props.stepInfos[`paymentDetailsCheckbox${index}TextBefore${i}`]) {
        label.push(this.getLabel(index, i, i));
      }
    }
    return label;
  };
  

  getLabel = (index, linkNumber, key) => {
    const contract = JSON.parse(localStorage.getItem('contract'));
    const termAndConditionDocumentUrl = extractTermAndConditionDocumentUrl(contract);
    const ipidDocumentUrl = extractIpidDocumentUrl(contract);
    return (
      <span key={`label-${index}-${linkNumber}`}> {/* Assign a unique key here */}
        {this.props.stepInfos[`paymentDetailsCheckbox${index}TextBefore${linkNumber}`]}{' '}
        <a
          href={linkTargetToUrl(this.props.stepInfos[`paymentDetailsCheckbox${index}LinkTarget${linkNumber}`], termAndConditionDocumentUrl, ipidDocumentUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {this.props.stepInfos[`paymentDetailsCheckbox${index}LinkText${linkNumber}`]}
        </a>
        {' '} {this.props.stepInfos[`paymentDetailsCheckbox${index}TextAfter${linkNumber}`]}
      </span>
    );
  };

  render() {
    if (!this.state.url || !this.props.DCX) {
      return false;
    }

    return (
      <div>
        {!this.state.displayError && !this.state.displayPurchaseError &&        
        <div id="payment-details" className="header" ref={this.paymentDetailsRef} tabIndex={-1}>
          <span>{this.props.stepInfos.paymentDetailsTitle || 'Payment details'}</span>
        </div>        
        }
        {
          !this.props.valid &&
          <div className="msg-info">{this.props.stepInfos.paymentDetailsValidateConditions}</div>
        }
        <div className="container-checkbox">
          {
            (this.props.stepInfos.paymentDetailsCheckbox1TextBefore1 ||
              this.props.stepInfos.paymentDetailsCheckbox1LinkText1) && !this.state.displayError && !this.state.displayPurchaseError &&
              <Field
                key="checkbox1"
                name="checkbox1"                
                label={this.buildLabel(1)}
                component={CustomCheckbox}
                preventLabelClick
                errorMsg={this.props.stepInfos.paymentDetailsCheckbox1Error}
                validate={required}
              />
          }

          {
            (this.props.stepInfos.paymentDetailsCheckbox2TextBefore1 ||
              this.props.stepInfos.paymentDetailsCheckbox2LinkText1) && !this.state.displayError && !this.state.displayPurchaseError &&
              <Field
                key="checkbox2"
                name="checkbox2"                
                label={this.buildLabel(2)}
                component={CustomCheckbox}
                preventLabelClick
                errorMsg={this.props.stepInfos.paymentDetailsCheckbox2Error}
                validate={required}
              />
          }

          {
            (this.props.stepInfos.paymentDetailsCheckbox3TextBefore1 ||
              this.props.stepInfos.paymentDetailsCheckbox3LinkText1) && !this.state.displayError && !this.state.displayPurchaseError &&
              <Field
                key="checkbox3"
                name="checkbox3"                
                label={this.buildLabel(3)} 
                component={CustomCheckbox}
                preventLabelClick
                errorMsg={this.props.stepInfos.paymentDetailsCheckbox3Error}
                validate={required}
              />
          }
        </div>

        {
          this.props.valid && !this.state.displayPurchaseError &&
          <iframe title="iframe-payment" id="iframe-payment" className="iframe-payment" src={this.state.url} />
        }
        {this.state.displayError &&
        <div className="error-purchase"> {I18n.t('wsError.ONE_PAY_ERROR')}</div>
        }
        {this.state.displayCommitPolicyError &&
        <div className="error-purchase"> {I18n.t('wsError.COMMIT_POLICY_ERROR')}</div>
        }
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    termOfUseValidated: formValueSelector('paymentDetails')(state, 'checkbox1'),
  }),
  {},
)(reduxForm({
  form: 'paymentDetails',
})(PaymentDetails)));
