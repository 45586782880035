/* eslint-disable max-len */
// @flow

import {
  SITE_CONFIG,
  STORE_DCX,
} from './siteActions';

const initialState = {
  config: null,
  DCX: null,
};

export default function siteReducer(state: any = initialState, action: { type: string, payload: any }) {
  switch (action.type) {
    case STORE_DCX: {
      return {
        ...state,
        DCX: action.payload.DCX,
      };
    }
    case SITE_CONFIG: {
      return {
        ...state,
        config: action.payload.siteConfig,
      };
    }
    default:
      return state;
  }
}

