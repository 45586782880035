/* eslint-disable arrow-parens */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, withRouter } from 'react-router-dom';
import HealthDeclaration from './steps/HealthDeclaration';
import { notifyError } from '../../network/network';
import StepsContainer from './component/StepsContainer';
import bookingPathApi from '../../network/api/bookingPathApi';
import ProductQuote from './ProductQuote';
import {
  FORM_CONFIRMATION,
  FORM_HEALTH_DECLARATION,
  FORM_PAYMENT_DETAILS,
  FORM_PERSONAL_DETAILS,
  FORM_PRODUCT_QUOTE,
} from '../../const';
import PersonalDetails from './steps/PersonalDetails';
import PaymentDetails from './steps/PaymentDetails';
import ConfirmationPage from './steps/ConfirmationPage';
import type { DCXType } from '../../types/DCXTypes';
import { buildFrontUrl, siteVersion } from '../../routes';
import EQSApi, {
} from '../../network/api/eqsApi';
import PurchaseApi from '../../network/api/purchaseApi';
import { buildPrebooking } from './request/prebooking';
import { buildPurchaseRequest } from './request/purchase';
import { Store } from 'react-notifications-component';

type Props = {
  match: Object,
  dispatch: Function,
  DCX: DCXType,
  siteData: Object,
}

type State = {
  loaded: boolean,
  loading: boolean,
  displayPurchaseError: string,
}

class FormStep extends React.Component<Props, State> {
  state = {
    bookingPathConf: null,
    data: {},
    navigation: {},
    breadcrumb: {},
    quotationIptTaxes: '',
    loaded: false,
    loading: false,
    displayPurchaseError: false,
    stepsOrder: [FORM_PRODUCT_QUOTE, FORM_HEALTH_DECLARATION, FORM_PERSONAL_DETAILS, FORM_PAYMENT_DETAILS, FORM_CONFIRMATION],
  };

  fetchStepInfos = (nextProps) => {
    bookingPathApi
      .get(nextProps.match.params, this.props.siteData.data.lineOfBusiness, siteVersion)
      .then(res => res.json())
      .then(stepInfos => this.setState({ stepInfos }))
      .catch(
        notifyError(this.props.dispatch, I18n.t('wsError.custom.BOOKING_PATH'))             
      );
  };

  fetchBreadcrumb = () => {
    bookingPathApi
      .get({
        ...this.props.match.params,
        step: 'mainInfos',
      }, this.props.siteData.data.lineOfBusiness, siteVersion).then(res => res.json())
      .then((data) => {
        this.setState({ breadcrumb: data.breadcrumb, quotationIptTaxes: data.quotationIptTaxes });
      })
      .catch(
        notifyError(this.props.dispatch, I18n.t('wsError.custom.BOOKING_PATH'))
      );
  };

  findCurrentStep = (array, currentName) => array.indexOf(currentName);

  findStepName = (array, currentName, delta) => {
    const indexOf = array.indexOf(currentName);
    const index = indexOf + delta;

    if (index >= 0 && index < array.length) {
      return array[index];
    }
    return array[indexOf];
  };

  componentRef: ?HTMLElement;

  componentDidMount() {
    bookingPathApi
      .config()
      .then(response => response.json())
      .then((bookingPathConf) => {
        this.setState({ bookingPathConf }, () => {
          this.setState({
            loaded: true,
            stepsOrder: this.state.stepsOrder.filter(step => step !== FORM_HEALTH_DECLARATION || this.props.siteData.bookingPathConfiguration.showHealthDeclaration),
          });

          try {
            this.fetchBreadcrumb();            
          } catch (e) {            
            Store.addNotification({
              title: "Error!",      
              message: I18n.t('wsError.custom.SITE_FETCH_COUNTRY'),
              type: "Danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 10000,
                onScreen: true
              }
            })     
          }

          try {
            this.fetchStepInfos(this.props);            
          } catch (e) {
            Store.addNotification({
              title: "Error!",      
              message: I18n.t('wsError.custom.SITE_FETCH_STEPSINFO'),
              type: "Danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 10000,
                onScreen: true
              }
            })   
          }
        });
      });

    // Add keydown event listener to prevent Enter from submitting the form
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props && nextProps && this.props.match.params.step !== nextProps.match.params.step) {
      this.fetchStepInfos(nextProps);
    }
  }

  componentWillUnmount() {
    // Remove the event listener when component is unmounted
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    const target = event.target;

    // Allow Enter key for buttons of type submit and button
    if (event.key === 'Enter' && target.tagName === 'BUTTON' && 
        (target.type === 'submit' || target.type === 'button')) {
      return; 
    }    

    // Allow Enter key for select options
    if (event.key === 'Enter' && target.tagName === 'SELECT') {
      return;
    }   

    // Prevent Enter key for other elements
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  handleSubmitPersonalDetails = (data) => {
    this.setState({ loading: true });
    localStorage.setItem('personalDetails', JSON.stringify(data));
    const prebookingRequest = buildPrebooking(data, this.props.match.params, this.props.siteData);
    localStorage.setItem('prebookingRequest', JSON.stringify(prebookingRequest));
    EQSApi.prebooking(prebookingRequest, siteVersion)
      .then(response => response.text())
      .then((minuteOfConsultationId) => {
        const purchaseRequest = buildPurchaseRequest(this.props.match.params, this.props.siteData.data.broker, this.props.termOfUseValidated, minuteOfConsultationId);
        localStorage.setItem('purchaseRequest', JSON.stringify(purchaseRequest));
        PurchaseApi.purchase(purchaseRequest, siteVersion)
          .then(response => response.json())
          .then((response) => {
            // if user click on back while loading, don't redirect on response
            if (this.props.match.params.step === FORM_PERSONAL_DETAILS) {
              this.props.history.push({
                pathname: FORM_PAYMENT_DETAILS,
                search: window.location.search,
                state: {
                  policyNumber: response.policyNumber,
                },
              });
            }
            const commitPolicy = {
              dcx: this.props.match.params,
              payer: purchaseRequest.payer,
              contractNumber: response.policyNumber,
            };
            localStorage.setItem('commitPolicy', JSON.stringify(commitPolicy));
            this.setState({ loading: false });
            window.removeEventListener('message', this.onePayResponseManagement);
          })
          .catch((e) => {            
            this.setState({ displayPurchaseError: true, loading: false });     
            console.log("Purchase API Error: ", e.message);     
            Store.addNotification({
              title: "Error!",      
              message: I18n.t('wsError.custom.PURCHASE'),
              type: "Danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 10000,
                onScreen: true
              }
            })                         
          });
      })
      .catch(e => {
        this.setState({ loading: false });        
        console.log("Prebooking API Error: ", e.message); 
        Store.addNotification({
          title: "Error!",      
          message: I18n.t('wsError.custom.PRE_BOOKING'),
          type: "Danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 10000,
            onScreen: true
          }
        });
      });
  };

  handleSubmitPaymentDetails = (data) => {
    localStorage.setItem('paymentDetails', JSON.stringify(data));
  };

  render() {
    if (!localStorage.getItem('quoteFormFront') || !JSON.parse(localStorage.getItem('quoteFormFront')) == {}) {
      window.location.href = `${window.location.origin}${buildFrontUrl(this.props.match.params, '')}`;
    }
    if (!this.props.siteData.quoteForm || !this.state.breadcrumb || !this.state.stepInfos || !this.state.loaded || !this.state.bookingPathConf) {
      return false;
    }
    const showTaxes = this.state.bookingPathConf.fiscalCodeBU.indexOf(this.props.match.params.countryCode) !== -1;
    if (this.props.match.params.step === FORM_PRODUCT_QUOTE) {
      return (<ProductQuote
        nextStep={this.state.stepsOrder[1]}
        stepsNumber={this.state.stepsOrder.length}
        breadcrumb={this.state.breadcrumb}
        quotation={this.state.stepInfos}
        data={this.props.siteData.data}
        allianzLogo={this.props.siteData.allianzLogo}
        navigation={this.props.siteData.navigation}
        partnerLogo={this.props.siteData.partnerLogo}
        quoteForm={this.props.siteData.quoteForm}
        quotationIptTaxesLabel={this.state.quotationIptTaxes}
        showTaxes={showTaxes}
      />);
    }
    return (
      <div className="homeSite" ref={el => (this.componentRef = el)}>
        <StepsContainer
          stepInfos={this.state.stepInfos}
          steps={this.state.breadcrumb}
          stepsNumber={this.state.stepsOrder.length}
          currentStep={this.findCurrentStep(this.state.stepsOrder, this.props.match.params.step)}
          back={this.findStepName(this.state.stepsOrder, this.props.match.params.step, -1)}
          continue={this.findStepName(this.state.stepsOrder, this.props.match.params.step, 1)}
          callToAction={this.props.siteData.data.mainThemeColor}
          detailSummaryTitleColor={this.props.siteData.quoteForm.quoteStyle.detailSummaryTitleColor}
          detailSummaryPanelColor={this.props.siteData.quoteForm.quoteStyle.detailSummaryPanelColor}
          quoteForm={this.props.siteData.quoteForm}
          DCX={this.props.DCX}
          quotationIptTaxesLabel={this.state.quotationIptTaxes}
          showTaxes={showTaxes}
          resetPurchaseError={() => {
            this.setState({ displayPurchaseError: false });
          }}
        >
          {{
            [FORM_HEALTH_DECLARATION]: (
              <HealthDeclaration
                stepInfos={this.state.stepInfos}
                breadcrumb={this.state.breadcrumb}
                handleSubmit={() => {
                }}
                name="healthDeclaration"
                consts={this.state.bookingPathConf}
              />
            ),
            [FORM_PERSONAL_DETAILS]: (
              <PersonalDetails
                stepInfos={this.state.stepInfos}
                breadcrumb={this.state.breadcrumb}
                handleSubmit={this.handleSubmitPersonalDetails}
                name="personalDetails"
                LOB={this.props.siteData.data.lineOfBusiness}
                consts={this.state.bookingPathConf}
                data={this.props.siteData.data}
                loading={this.state.loading}
                displayPurchaseError={this.state.displayPurchaseError}
                resetPurchaseError={() => this.setState({ displayPuchaseError: false })}
              />
            ),
            [FORM_PAYMENT_DETAILS]: (
              <PaymentDetails
                stepInfos={this.state.stepInfos}
                breadcrumb={this.state.breadcrumb}
                handleSubmit={this.handleSubmitPaymentDetails}
                name="paymentDetails"
                DCX={this.props.DCX}
                useBroker={this.props.siteData.data.broker}
                consts={this.state.bookingPathConf}
              />
            ),
            [FORM_CONFIRMATION]: (
              <ConfirmationPage
                stepInfos={this.state.stepInfos}
                breadcrumb={this.state.breadcrumb}
                name="confirmation"
                callToAction={this.props.siteData.quoteForm.quoteStyle.callToAction}
                consts={this.state.bookingPathConf}
                refToPrint={this.componentRef}
              />
            ),
            default: (
              <Redirect to={FORM_PRODUCT_QUOTE} />
            ),
          }[this.props.match.params.step]}
        </StepsContainer>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  DCX: state.site.DCX,
  siteData: state.site.config,
  bookingPath: state.form.bookingPathForm,
}))(FormStep));
