/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
// @flow

import React from 'react';

type Props = {
  fields: Object[],
  changeForm: Function,
  min: number,
  max: number,
  placeholder: string,
  travellersWording: string,
  input: {
    value: Object,
    name: string
  }
}

type State = {
  open: boolean
}

export default class MultiSelectPassenger extends React.Component<Props, State> {
  state = {
    open: false,
  };

  static defaultProps = {
    min: 0,
    max: 99,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.open && this.state.open) {
      // When the popup opens, focus on the first button inside the popup
      const firstButton = document.querySelector('.select-space button');
      if (firstButton) firstButton.focus();
    }
  }

  changeValue = (fieldName, value) => {
    this.props.changeForm(fieldName, value);
  };

  increment = (fieldName, value) => {
    if (value < this.props.max) this.changeValue(fieldName, value + 1);
  };

  decrement = (fieldName, value) => {
    if (value > this.props.min) this.changeValue(fieldName, value - 1);
  };

  close = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState(prev => ({ open: !prev.open }));
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.close(); // Close the popup when the 'Escape' key is pressed
    }
  };

  resultPhrase = (value) => {
    if (!value || Object.keys(value).filter(key => !!value[key]).length === 0) return;
    if (Object.keys(value).filter(key => !!value[key]).length === 1) {
      return Object.keys(value)
        .filter(key => !!value[key])
        .map(key => `${value[key]} ${this.props.fields.find(field => field.name === key).label}`)
        .join(', ');
    }
    return Object.keys(value)
      .filter(key => !!value[key])
      .reduce((acc, key) => acc + value[key], 0) + ' ' + this.props.travellersWording;
  };

  render() {
    const { open } = this.state;
    const { value, name } = this.props.input;
    const resultPhrase = this.resultPhrase(value);

    return (
      <div className="multi-select-passenger" onKeyDown={this.handleKeyDown}>
        <div
          className="input-view"
          onClick={this.toggle}
          role="button"
          aria-expanded={open}
          aria-haspopup="true"
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && this.toggle()}
        >
          <div className="result-phrase">{resultPhrase || this.props.placeholder}</div>
        </div>
        {open && (
          <div className={`select-space${open ? ' select-space--open' : ''}`} role="dialog" aria-modal="true">
            <div className="container-list">
              {this.props.fields && this.props.fields.map(field => (
                <div key={field.name} className="line">
                  <span>
                    <strong>{field.label}</strong>
                    {field.labelDesc && field.labelDesc.length > 0 ? <br /> : null}
                    {field.labelDesc && field.labelDesc.length > 0 ? field.labelDesc : null}
                  </span>
                  <div className="values">
                    <button
                      type="button"
                      className="left"
                      aria-label={`Decrease ${field.label}. ${field.labelDesc ? field.labelDesc + '. ' : ''}Current value is ${value[field.name] || 0}`}
                      onClick={() => this.decrement(`${name}.${field.name}`, value[field.name] || 0)}
                    >
                      -
                    </button>
                    <span>{value[field.name] || 0}</span>
                    <button
                      type="button"
                      className="right"
                      aria-label={`Increase ${field.label}. ${field.labelDesc ? field.labelDesc + '. ' : ''}Current value is ${value[field.name] || 0}`}
                      onClick={() => this.increment(`${name}.${field.name}`, value[field.name] || 0)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="container-buttons">
              <button type="button" onClick={() => this.props.changeForm(name, null)} aria-label="Clear selection">
                <i className="fa fa-times red" />
              </button>
              <button type="button" onClick={this.close} aria-label="Confirm selection">
                <i className="fa fa-check green" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
