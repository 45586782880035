// @flow

import { RestClient } from '../network';
import type { PurchaseRQ } from '../../types/PurchaseTypes';

const PurchaseApi = {
  purchase: (purchaseRQ: PurchaseRQ, version: string) =>
    new RestClient(`/purchase?version=${version}`, 'POST').jsonBody(purchaseRQ).execute(),
};

export default PurchaseApi;
