/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-underscore-dangle */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'react-string-format';
import { buildFrontUrl } from '../../../routes';
import { getEnvironment } from '../../../helper/helper';

type Props = {
  stepInfos: Object,
  breadcrumb: {},
  callToAction: string,
  history: Object,
  location: Object,
  match: Object,
}

class ConfirmationPage extends React.Component<Props> {
  print = () => {
    window.print();
  };

  // Create a ref for policy information element
  policyInfoRef = React.createRef(); 

  componentDidMount() {
    //! ECOMDEV-40085 - Adobe script implementation

    const commitPolicy = JSON.parse(localStorage.getItem('commitPolicy'));
    const prebookingRequest = JSON.parse(localStorage.getItem('prebookingRequest'));

    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Page Title
    if (this.props.breadcrumb.quoteStep5 === null || this.props.breadcrumb.quoteStep5 === undefined) {
      document.title = this.props.breadcrumb.quoteStep4;
    } else {
      document.title = this.props.breadcrumb.quoteStep5;
    }

    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Confirmation',
          pageType: 'Confirmation',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
      product: [{
        id: prebookingRequest.travel.productSign, // "TMSFICANWL2018"
        name: prebookingRequest.travel.productName, // "Missed Event Insurance"
        price: prebookingRequest.travel.totalAmount, // "16.2"
        quantity: 1,
      }],
      transaction: {
        currency: prebookingRequest.travel.currency, // EUR
        purchaseId: commitPolicy.contractNumber, // "TMSFIW100000007"
        totalPrice: prebookingRequest.travel.totalAmount, // "16.2"
        totalTaxes: '0', // "0"
        totalQuantity: 1, // "1"
        uniqueTransactionId: commitPolicy.transactionId, // "TX00U331GO3G3J02"
      },
    };

    // eslint-disable-next-line no-underscore-dangle
    window._satellite.track('purchase');

    // console.log('-- Confirmation --');
    // console.log(window.digitalData);

    // Set focus on initial component mount
    if (this.policyInfoRef.current) {
      this.policyInfoRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Only set focus on initial load and not on state updates
    if (!this.state.initialFocusSet && this.policyInfoRef.current) {
      this.policyInfoRef.current.focus();
      this.setState({ initialFocusSet: true }); // Set the initial focus flag to true
    }
  }

  render() {
    const commitPolicy = JSON.parse(localStorage.getItem('commitPolicy'));
    const policyNumber = commitPolicy.contractNumber;
    return (
      <div id="confirm-print" ref={this.policyInfoRef} tabIndex={-1} className="confirmation-page" >
        <header id="policy-info-header" ref={this.policyInfoRef} tabIndex={0} aria-label="Policy Information"> 
          <h1>{this.props.stepInfos.pageTitle}</h1>
        </header>
        <section className="confirmation-block uppercase thanks">
          <h2>{this.props.stepInfos.thankYou}</h2>
        </section>
        <section className="confirmation-block policy">
          <i className="fa fa-edit icon" aria-hidden="true" />
          <div className="policy-number" aria-labelledby="policy-info-header">
            <div>
              <span className="sr-only">Policy Number: </span>
              {this.props.stepInfos.policyNumber}
            </div>
            <div className="number uppercase">
              <span className="sr-only">Policy Number: </span> 
              {policyNumber}
            </div>
          </div>
        </section>
        <section className="confirmation-block message"  aria-labelledby="policy-info-header">
          <p>
            {`${this.props.stepInfos.youWillReceive} ${this.props.stepInfos.emailTo} ${this.props.stepInfos.within4Hours}. ${this.props.stepInfos.emailText} ${this.props.stepInfos.emailAssistance}`}
          </p>
        </section>
        <section className="confirmation-block terminate">
          <div className="recommendation" role="button" tabIndex={0} onClick={this.print} onKeyPress={(e) => { if (e.key === 'Enter' || e.key === ' ') this.print(); }}>
            <i className="fa fa-print" aria-hidden="true" /> {this.props.stepInfos.printThisPage}
          </div>
          <button
            type="button"
            className="button"
            style={{ background: this.props.callToAction }}
            onClick={() => this.props.history.push(buildFrontUrl(this.props.match.params, ''))}
          >
            {this.props.stepInfos.restart}
          </button>
        </section>
      </div>
    );
  }
}

export default withRouter(connect(
  () => ({}),
  {},
)(ConfirmationPage));

