const en = {
  wsError: {
    custom: {
      BOOKING_PATH_CONST: 'Booking Path const error',
      BOOKING_PATH_DATA: 'Booking Path data error',
      SITE_GET_DATA: 'Site get data error',
      SITE_GET_SITE_VERSION: 'Site get site version error',
      SITE_FETCH_PARTNER: 'Site fetch partner error',
      SITE_FETCH_COUNTRY: 'Site fetch country error',
      SITE_FETCH_STEPSINFO: 'Site fetch step info error',
      SITE_FETCH_BREADCRUMB: 'Site fetch breadcrumb error',
      SITE_GET_DCX: 'Site get DCX error',
      SITE_GET_DCX_ID: 'Site get DCX ID error',
      QUOTATION: 'The quotation request failed',
      PRE_BOOKING: 'The Prebooking service failed to create new pending policy',
      PURCHASE: 'The purchase request failed',
      ONE_PAY: 'The One Pay service failed to create new session',
      MISSING_MINUTE_OF_CONSULTATION: 'There was a problem in the recuperation on the minute of consultation',
    },
    INTERNAL_ERROR: 'An internal error occured, please try again later',
    FIELD_REQUIRED: 'Field \'%{field0}\' is required',
    COLOR_INVALID: 'Field \'%{field0}\' must be an hexadecimal color: #ff00ff',
    EMAIL_INVALID: 'Email address \'%{field0}\' is invalid',
    USERNAME_ALREADY_EXISTS: 'This username is already used, please choose another one',
    EMAIL_ALREADY_EXISTS: 'This email address is already used, please choose another one',
    PASSWORDS_DIFFERENT: 'Passwords \'%{field0}\' et \'%{field1}\' must match',
    WRONG_LOGIN_OR_PASSWORD: 'The username or password is incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Due to login attempt errors, your account is locked for %{field0} minutes, please try again later',
    ROLE_LABEL_EXISTS: 'A role already exists with this label',
    PASSWORD_TOO_SHORT: 'The password is too short, it must be at least %{field0} characters long',
    MESSAGE: '%{field0}',
    SITE_NOT_FOUND: 'The site you are trying to access does not exists',
    PURCHASE_ERROR: 'An error occured during purchase',
    ONE_PAY_ERROR: 'An error occured during OnePay',
    COMMIT_POLICY_ERROR: 'An error occured during commit policy',
  },
  date: {
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Start date',
    DATE_END: 'End date',
    DATE_FORMAT: 'MM/DD/YYYY',
  },
  quoteForm: {
    title: {
      mainForm: 'Configure your %{LOB} quote form',
      additionnalFields: 'Add additional quotation fields',
      labelTranslation: 'Quote form label translation',
      summary: 'Summary block translations',
      colorPickers: 'Quote form style customization',
    },
    mainForm: {
      tripType: 'Trip type',
      residenceCountry: 'Country of residence',
      departureCountry: 'Departure country',
      destination: 'Destination',
      travellers: 'Travellers',
      startDate: 'Start date',
      endDate: 'End Date',
      travelDuration: 'Duration of employees travel',
      answers: {
        single: 'Single',
        annual: 'Annual',
        singleAnnual: 'Single / Annual',
        oneCountry: 'One country',
        multiCountries: 'Selectable country',
        countries: 'Countries',
        geozone: 'Geozone',
        number: 'Number',
        ageband: 'Age bands',
        date: 'Day',
        dateTime: 'Day / Hours / Minutes',
        minimumDays: 'Minimum days',
        maximumDays: 'Maximum days',
      },
    },
    additionnalFields: {
      tripCost: 'Trip cost',
      bookingDate: 'Booking date',
      rentalObject: 'Rental object',
      relationshipStatus: 'Relationship status',
      destination: 'Destination',
      eventName: 'Event name',
      carHireReference: 'Car hire reference',
      travellers: 'Travellers',
      residenceCountry: 'Country of residence',
      multiCountries: 'Selectable country',
      oneCountry: 'One country',
      countries: 'Countries',
      ageBands: 'Age bands',
      geozone: 'Geozone',
      promoCode: 'Promo code',
    },
    labelTranslation: {
      titleLine1: 'Title line 1',
      titleLine2: 'Title line 2',
      tripType: 'Trip type',
      singleTrip: 'Single trip',
      annualTrip: 'Annual trip',
      residenceCountry: 'Country of residence',
      residenceCountryConfirmation: 'Residence country confirmation',
      residenceCountryErrorMsg: 'Error message if no residence',
      selectResidenceCountry: 'Select country of residence',
      departureCountry: 'Departure country',
      departureCountryErrorMsg: 'Error message if no departure',
      selectDepartureCountry: 'Select departure country',
      destination: 'Destination',
      destinationErrorMsg: 'Error message if no destination',
      selectDestination: 'Select destination country',
      startDate: 'Start date',
      startDateErrorMsg: 'Error start date message',
      endDate: 'End Date',
      endDateErrorMsg: 'Error end date message',
      annualStartDate: 'Annual start date',
      annualStartDateMsg: 'Error annual start date message',
      annualEndDate: 'Annual end Date',
      annualEndDateMsg: 'Error annual end date message',
      adult: 'Adult',
      adultAgeBandLabel: 'Adult age band',
      child: 'Child',
      childAgeBandLabel: 'Child age band',
      infant: 'Infant',
      infantAgeBandLabel: 'Infant age band',

      rentalObject: 'Rental object',
      relationshipStatus: 'Relationship status',
      relationshipDescription: 'Relationship status description',
      relationshipStatusErrorMsg: 'Select relationship status',

      family: 'Family',
      single: 'Single',
      couple: 'Couple',
      group: 'Group',
      price: 'Price',
      getAQuote: 'GET A QUOTE',
      priceErrorMsg: 'Error price',

      eventDate: 'Event date',
      eventName: 'Event Name',
      numberOfTickets: 'Number of tickets',
      bookingValue: 'Booking value',
      bookingDate: 'Booking Date',

      employeeTravelDuration: 'Employee travel duration',
      numberOfTravellingDaysPerYear: 'Number of travelling days per year',
      numberOfTravellingEmployee: 'Number of travelling employee',
      insuranceStartDate: 'Insurance start date',

      promoCode: 'Promo code',
      getQuote: 'Get a quote',
    },
    summary: {
      summary: 'Summary',
      insuranceCover: 'Insurance covers',
      startDate: 'Date from which the insurance starts',
      endDate: 'Date at which the insurance ends',
      annualStartDate: 'Date from which the annual insurance starts',
      annualEndDate: 'Date at which the annual insurance ends',
      productName: 'Product name',
      travelFromCountry: 'Country travel from',
      travelTo: 'Travel to',
      currencyDetail: 'Currency detail',
      currencyLabel: 'Currency label',
      traveller: 'Traveller',
      travellers: 'Travellers',
      detailMessage: 'For full detail message',
      termsAndConditions: 'Terms and Conditions',
      totalPrice: 'Total price',
      promoCode: 'Promo code',
    },
    colorPickers: {
      fontColorTitle: 'Font-color of the text above the quotation block',
      callToAction: 'Call to action button',
      detailSummaryTitleColor: 'Font-color of the text above the detail summary block',
      detailSummaryPanelColor: 'Detail summary panel color ',
    },
  },
  bookingPathForm: {
    title: {
      breadCrumb: 'BreadCrumb (steps)',
      quotation: 'Quotation',
      personnalDetails: 'Personnal details',
      paymentDetails: 'Payment details',
      confirmation: 'Confirmation',
    },
    breadCrumb: {
      quoteStep1: 'Quote Step 1',
      quoteStep2: 'Quote Step 2',
      quoteStep3: 'Quote Step 3',
      quoteStep4: 'Quote Step 4',
    },
    quotation: {
      select: 'Select',
      replyDetail: 'Replys details',
      viewDetails: 'View details',
      noProductErrorMsg: 'Error no product',
      promoCodeErrorMsg: 'Error invalid promo code',
    },
    healthConfirmation: {
      confirm: 'Confirm',
    },
    personnalDetails: {
      pageTitle: 'Page Title',
      title: 'Title',
      degree: 'Degree',
      firstName: 'First Name',
      lastName: 'Last Name',
      address: 'Address',
      city: 'City',
      residenceCountry: 'Country of residence',
      postalCode: 'Postal Code',
      phone: 'Phone',
      email: 'Email',
      dateOfBirth: 'Date of birth',
      taxCode: 'Tax code',
      contractHolder: 'Contract holder checkbox',
      fieldEmptyErrorMsg: 'Error message if a field is empty',
      otherPersonInsured: 'Other person insured',
      broker: 'Broker',
      insuredPerson: 'I confirm that I am the insured person',
      otherPersonInsuredFields: {
        title: 'Title',
        degree: 'Degree',
        firstName: 'First Name',
        lastName: 'Name',
        dateOfBirth: 'Date of birth',
      },
      brokerFields: {
        agentId: 'Agent ID',
        agentEmail: 'Agent Email',
      },
    },
    paymentDetails: {
      paymentPageTitle: 'Payment page title',
      check1TextBefore: 'Checkbox 1 : text before',
      check1LinkText: 'Checkbox 1 : link text',
      check1TextAfter: 'Checkbox 1 : text after',
      check1ErrorMsg: 'Checkbox 1 : error message if not confirm',
      check2TextBefore: 'Checkbox 2 : text before',
      check2LinkText: 'Checkbox 2 : link text',
      check2TextAfter: 'Checkbox 2 : text after',
      check2ErrorMsg: 'Checkbox 2 : error message if not confirm',
      cardTypeEmptyErrorMsg: 'Card type message if empty',
      cardNumberEmptyErrorMsg: 'Card number message if empty',
      cardNumberIncorrectErrorMsg: 'Card number message if incorrect',
      cardHolderEmptyErrorMsg: 'Card holder message if empty',
      securityCodeEmptyErrorMsg: 'Security code message if empty',
      securityCodeIncorrectErrorMsg: 'Security code message if incorrect',
      expirationDateErrorMsg: 'Expiration date message',
    },
    confirmation: {
      pageTitle: 'Page title',
      confirmationTitle: 'Confirmation title',
      policyNumber: 'Policy number',
      youWillReceive: 'You will receive',
      within4Hours: 'Within 4 hours',
      contactUs: 'Contact us',
      emailTo: 'Email to',
      emailText: 'Email text',
      emailAssitance: 'Email Assistance',
      thankYou: 'Thank you',
      emergencyText: 'Emergency text',
      printPage: 'Print this page',
      restart: 'Restart',
    },
  },
  bookingPath: {
    productQuote: {
      noProduct: 'NO PRODUCT FOUND',
    },
  },
  summary: {
    title: 'SUMMARY',
    insuranceCoverFrom: 'Insurance covers from ',
    productName: 'Product name ',
    from: 'From ',
    to: ' to ',
    prices: 'All prices and the limits are in ',
    traveller: 'Traveller ',
    readConditions: 'For full policy details please read the',
    totalPrice: 'Total price for all insured persons ',
  },
  placeholder: {
    destination: 'Select a destination',
    residenceCountry: 'Select a residence country',
    departureCountry: 'Select a departure country',
    relationShipStatus: 'Select a relationship status',
    number: 'Select a number',
    selectPrice: 'Select a price',
    selectCarHireReference: 'Select a car hire reference',
    selectNumberOfTravellingDaysPerYear: 'Select a number of travelling days per year',
    selectPromoCode: 'Please enter a promo code',
  },
  DCX: {
    noDCX: 'There is no offer for this combination.',
  },
};

export default en;
