/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-underscore-dangle */
// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { format } from 'react-string-format';
import { required } from '../../../components/validation/ValidationFunctions';
import CustomCheckbox from '../../homeSite/component/CustomCheckbox';
import { getEnvironment } from '../../../helper/helper';

type Props = {
    stepInfos: Object,
    breadcrumb: {},
}

class HealthDeclaration extends React.Component<Props> {
  componentDidMount() {
    //! ECOMDEV-40085 - Adobe script implementation
    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}/{6}/{7}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6], PageURLElements[7], PageURLElements[8]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Page Title
    if (this.props.breadcrumb.quoteStep5 === null || this.props.breadcrumb.quoteStep5 === undefined) {
      document.title = 'Health Declaration';
    } else {
      document.title = this.props.breadcrumb.quoteStep2;
    }

    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Health Declaration',
          pageType: 'Health Declaration',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
    };
    console.log('-- Health Declaration --');
    console.log(window.digitalData);
  }

  render() {
    if (!this.props.stepInfos) {
      return false;
    }
    return (
      <div>
        <div className="header">
          <span>{this.props.stepInfos.healthDeclarationTitle}</span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.props.stepInfos.healthDeclarationContent }} />
        <div>
          <Field
            key="confirm"
            name="confirm"
            label={I18n.t('bookingPathForm.healthConfirmation.confirm')}
            component={CustomCheckbox}
            validate={[required]}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  () => ({}),
  {},
)(HealthDeclaration));

