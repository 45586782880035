/* eslint-disable max-len */
// @flow

import { RestClient } from '../network';
import { CreateSessionRQ } from '../../types/PaymentTypes';

const OnePayApi = {
  getSession: (createSession: CreateSessionRQ, partnerCode: string, countryCode: string, langCode: string, version: string) =>
    new RestClient(`/onePay/${partnerCode}/${countryCode}/${langCode}?version=${version}`, 'POST').jsonBody(createSession).execute(),
};

export default OnePayApi;
