/* eslint-disable react/no-array-index-key */
// @flow

import React from 'react';
import { Field } from 'redux-form';
import { required } from '../../../../components/validation/ValidationFunctions';

type Props = {
  input: {
    value: string,
    onChange: string,
    name: string,
  },
  label: string,
  values: Object[],
  meta: Object,
}

export default function ContainerRadio(props: Props) {
  return (
    <fieldset className="field-radio">
      <legend className="label-radio">{props.label}</legend>
      <div className="container-radio" role="radiogroup" aria-labelledby={`${props.input.name}-legend`}>
        {
          props.values.map((val, index) => (
            <div className="input-radio" key={`${val.value}${index}`}>
              <Field
                name={props.input.name}
                id={`${props.input.name}.${val.value}`}
                type="radio"
                component="input"
                value={val.value}
                validate={[required]}
                aria-checked={props.input.value === val.value}
                aria-labelledby={`${props.input.name}-label-${index}`}
              />
              <label
                className="label"
                htmlFor={`${props.input.name}.${val.value}`}
                id={`${props.input.name}-label-${index}`}
              >
                {val.label || 'Label to define'}
              </label>
            </div>
          ))
        }
      </div>
      {props.meta.error && props.meta.touched && (
        <span className="text-error" role="alert">
          {props.meta.errorMsg || props.meta.error}
        </span>
      )}
    </fieldset>
  );
}
