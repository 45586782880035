/* eslint-disable quote-props */
// @flow

export const baseApiUrl = '/wl-factory/api';

export const ROOT_PAGE_ROUTE = '/WL';

export const Version = {
  STAGING: 'STAGING',
  LIVE: 'LIVE',
};

export const HOME_PAGE_ROUTE = `${ROOT_PAGE_ROUTE}/:partnerCode/:countryCode/:langCode`;

export const FORM_ROOT_PAGE_ROUTE = '/form';

export const FORM_STEP_PAGE_ROUTE = `${ROOT_PAGE_ROUTE}/:partnerCode/:countryCode/:langCode${FORM_ROOT_PAGE_ROUTE}/:step`;

export const FORM_PRODUCT_QUOTE = 'product-quote';
export const FORM_HEALTH_DECLARATION = 'health-declaration';
export const FORM_PERSONAL_DETAILS = 'personal-details';
export const FORM_PAYMENT_DETAILS = 'payment-details';
export const FORM_CONFIRMATION = 'confirmation';
export const CONTACT_US = `${ROOT_PAGE_ROUTE}/:partnerCode/:countryCode/:langCode/contact-us`;
export const NOTICE_PAGE = `${ROOT_PAGE_ROUTE}/:partnerCode/:countryCode/:langCode/notice-page`;

export const SINGLE = 'Single';

export const QUOTE_FORM_PARAMS = [
  'tripType',
  'residenceCountry',
  'destination',
  'startDate',
  'endDate',
  'travellers',
  'bookingDate',
  'rentalObject',
  'eventDate',
  'eventName',
  'numberOfTickets',
  'bookingValue',
  'tripCost',
  'title',
  'degree',
  'lastName',
  'firstName',
  'dateOfBirth',
  'address',
  'address2',
  'address3',
  'city',
  'zipCode',
  'country',
  'phone',
  'email',
  'fiscalCode',
  'insuredTitle',
  'insuredDegree',
  'insuredLastName',
  'insuredFirstName',
  'insuredDateOfBirth',
  'agentId',
  'agentEmail',
  'agency',
];

export const NEW = 'NEW'; // new field that didn't exits before WL-50
export const OLD = 'OLD'; // field that already exits before WL-50

export type FieldBookingPath = {
  name: string,
  status: OLD | NEW,
  index: number,
}

// eslint-disable-next-line max-len
export const getBookingMapValueForCountry = (countryCode: string, fieldBookingPath: FieldBookingPath) => {
  if (!bookingPathMap[countryCode]) {
    return fieldBookingPath.status === OLD;
  }
  const value = bookingPathMap[countryCode][fieldBookingPath.index];
  if (value === 'child') return true;
  if (value === 'adult') return true;
  if (fieldBookingPath.status === OLD) {
    if (value === null) return true;
    if (value === true) return true; // not supposed to happen
    if (value === false) return false;
  } else if (fieldBookingPath.status === NEW) {
    if (value === null) return false;
    if (value === true) return true;
    if (value === false) return false; // not supposed to happen
  }
  return false;
};


// if it an old field
// if toRemove (false in the array) => no display
// if toAdd (true in the array) => not supposed to happen
// if nothing (null in the array) => keep the display

// if it a new field
// if toRemove (false in the array) => not supposed to happen
// if toAdd (true in the array) => display
// if nothing (null in the array) => keep the non display

export const fieldBookingPath = {
  passport: { name: 'passport', status: NEW, index: 0 },
  tax: { name: 'tax', status: OLD, index: 1 },
  degree: { name: 'degree', status: OLD, index: 2 },
  zipCode: { name: 'zipCode', status: OLD, index: 3 },
  city: { name: 'city', status: OLD, index: 4 },
  address2: { name: 'address2', status: OLD, index: 5 },
  address3: { name: 'address3', status: OLD, index: 6 },
  dateOfBirth: { name: 'dateOfBirth', status: OLD, index: 7 },
  state: { name: 'state', status: OLD, index: 8 },

  // other insuredPerson
  otherLegalTitle: { name: 'otherLegalTitle', status: NEW, index: 9 },
  otherLegalFirstName: { name: 'otherLegalFirstName', status: NEW, index: 10 },
  otherLegalLastName: { name: 'otherLegalLastName', status: NEW, index: 11 },
  otherLegalTaxCode: { name: 'otherLegalTaxCode', status: NEW, index: 12 },
  otherDegree: { name: 'otherDegree', status: OLD, index: 13 },
  otherTaxCode: { name: 'otherTaxCode', status: NEW, index: 14 },
  otherFirstName: { name: 'otherFirstName', status: OLD, index: 15 },
  otherDateOfBirth: { name: 'otherDateOfBirth', status: OLD, index: 16 },
};


export const bookingPathMap = {
  'BH': [true, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'EG': [true, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'JO': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'KW': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'QA': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'SG': [true, null, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null],
  'ZA': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'AE': [true, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'FR': [null, null, false, null, null, null, null, null, null, null, null, null, null, false, null, null, null],
  'MX': [true, null, null, null, null, false, false, null, null, null, null, null, null, null, null, null, null],
  'CH': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'BE': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'DK': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'GR': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'HU': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'FI': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'GB': [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'ID': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'IE': [null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, null],
  'LB': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'OM': [true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
  'PL': [null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, null],
  'PT': [null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, null],
  'SE': [null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, null, null],
};

export const childCodes = ['youth', 'child', 'infant'];
