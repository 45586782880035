/* eslint-disable max-len */
// @flow

import { RestClient } from '../network';
import type { ContractListRS, QuotationRQ } from '../../types/QuotationTypes';

const EQSApi = {
  quotation: (quotationRQ: QuotationRQ, version: string) =>
    new RestClient(`/quotation?version=${version}`, 'POST').jsonBody(quotationRQ).execute(),
  prebooking: (prebooking: Object, version: string) =>
    new RestClient(`/prebooking?version=${version}`, 'POST').jsonBody(prebooking).execute(),
  commitPolicy: (commitPolicy: Object, version: string) =>
    new RestClient(`/commit-policy?version=${version}`, 'POST').jsonBody(commitPolicy).execute(),
};

export const extractContractIptTax = (quotationContract: ContractListRS) => {
  if (!quotationContract || !quotationContract.recommendedPremium || !quotationContract.recommendedPremium.taxes) {
    return null;
  }
  const iptTaxes = quotationContract
    .recommendedPremium
    .taxes
    .filter(tax => typeof tax.totalTaxAmount === 'object');

  return iptTaxes && iptTaxes.length > 0 ? iptTaxes[0] : null;
};

const extractDocumentUrlByType = (quotationContract: ContractListRS, documentType: string) => {
  if (!quotationContract || !quotationContract.documents) {
    return null;
  }
  const termAndCond = quotationContract
    .documents
    .filter(document => document.documentInfo.documentType === documentType)
    .map(document => document.documentInfo.url);

  return termAndCond && termAndCond.length > 0 ? termAndCond[0] : null;
};
export const extractTermAndConditionDocumentUrl = (quotationContract: ContractListRS) => extractDocumentUrlByType(quotationContract, 'TAndC');
export const extractIpidDocumentUrl = (quotationContract: ContractListRS) => extractDocumentUrlByType(quotationContract, 'IDD');

export const extractDocumentsUrl = (quotationContract) => {
  if (!quotationContract || !quotationContract.documents) {
    return null;
  }
  return quotationContract.documents.map(document => document.documentInfo.url);
};

export const extractContractDestination = (quotationContract: ContractListRS) => {
  if (!quotationContract ||
    !quotationContract.travels || quotationContract.travels.length === 0 ||
    !quotationContract.travels[0].destinations || quotationContract.travels[0].destinations.length === 0 ||
    !quotationContract.travels[0].destinations[0].destinationLocationName) {
    return null;
  }
  return quotationContract
    .travels[0]
    .destinations[0]
    .destinationLocationName;
};

export const displayPrice = (price: number) => price.toFixed(2);

export default EQSApi;
