// @flow

import React from 'react';

type Props = {
  first: boolean,
  onNumberChange: Function,
  list: string[],
  value: string,
}

type State = {
  selectedNumber: string,
  focused: boolean,
  list: string[],
}

class TimePicker extends React.Component<Props, State> {
  state = {
    selectedNumber: '0',
    focused: false,
    list: [],
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({ focused: false });
  };
  setNumber = (num) => {
    this.props.onNumberChange(num);
    this.setState({
      selectedNumber: num,
      focused: false,
    });
  };

  render() {
    return (
      <div
        className="number-picker-input"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        tabIndex={0}
      >
        <div className={`number-picker-preview ${this.props.first ? 'first' : ''}`}>
          <div className="simul-text ">{this.props.value}</div>
          <select onChange={(e) => this.setNumber(e.target.value)}>
            {
              this.props.list.map(num => (
                <option key={num} value={num}>{num}</option>
              ))
            }
          </select>
          <div className="container-chevron">
            <i className="fa fa-chevron-up" />
            <i className="fa fa-chevron-down" />
          </div>
        </div>
      </div>
    );
  }
}

export default TimePicker;
