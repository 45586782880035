/* eslint-disable no-else-return */
/* eslint-disable max-len */
// eslint-disable-next-line no-else-return
// @flow

import moment from 'moment';

export const fieldEmpty = (message: string, field: string) => (value: string) => (value ? undefined : message);

export const isNumber = (message: string) => (value: string) => {
  const reg = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
  if (reg.test(value)) {
    return undefined;
  }
  return message;
};

export const dateRange = (messageStart: string, messageEnd: string) => (value: { startDate: Date, endDate: Date }) => {
  if (!value) {
    return messageStart;
  } else {
    if (!value.startDate) return messageStart;
    if (!value.endDate) return messageEnd;
  }
  return undefined;
};

export function validateEmail(email) {
  // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    return undefined;
  }
  return 'Invalid email format';
}

export function required(value) {
  return value ? undefined : 'Field required';
}

export function adultDate(momentValue) {
  momentValue.hours(0);
  const today = moment().hours(1);
  if (momentValue && today.diff(momentValue, 'years', true) >= 18) {
    return undefined;
  }
  return 'Adult only';
}

export const atLeastOne = (message: string) => (value: any) => {
  if (value && typeof value === 'object' && Object.entries(value).length > 0) {
    return undefined;
  }
  if (value && typeof value !== 'object' && value) {
    return undefined;
  }
  return message;
};

