// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'react-string-format';
import Tiles from './component/Tiles';
import QuoteFormContainer from './component/QuoteFormContainer';
import type { HomePageType } from '../../../../admin-ui/src/types/SiteTypes';
import { getEnvironment } from '../../helper/helper';

type Props = {
  DCX: DCXType,
  quoteForm: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  affiliations: Object[],
  // eslint-disable-next-line react/no-unused-prop-types
  homePage: HomePageType,
}

type State = {
}


class HomeSite extends React.Component<Props, State> {
  state = {
    isVisible: true,
    fluid: false,
    size: 180,
    windowWidth: window.innerWidth,
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  affiliation = this.props.affiliation;

  componentDidMount() {
    localStorage.removeItem('quotationRQ');
    localStorage.removeItem('quotationRS');
    localStorage.removeItem('contract');
    localStorage.removeItem('quoteFormFront');

    window.addEventListener('resize', this.handleResize);
    // eslint-disable-next-line prefer-destructuring
    const windowWidth = this.state.windowWidth;

    // ! Force dynamic control covid banner height support all mobile device.
    if (windowWidth > 800) this.setState({ size: 180 });
    else if (windowWidth > 768) this.setState({ size: 190 });
    else if (windowWidth > 735) this.setState({ size: 210 });
    else if (windowWidth > 665) this.setState({ size: 220 });
    else if (windowWidth > 641) this.setState({ size: 200 });
    else if (windowWidth > 569) this.setState({ size: 220 });
    else if (windowWidth > 415) this.setState({ size: 220 });
    else if (windowWidth > 319) this.setState({ size: 220 });
    else this.setState({ size: 270 });

    //! ECOMDEV-40085 - Adobe script implementation
    // URL Element : window.location.href.split('/')
    // let urlElements = ['https:', '', 'www.magroup-online.com', 'WL', 'HBA', 'DE', 'de']
    const PageURLElements = window.location.href.split('/');
    // Server : www.magroup-online.com
    const currentServer = PageURLElements[2];

    // Environment
    const currentEnvironment = getEnvironment(currentServer, window.location.href.includes('version=STAGING'));

    // Language : de
    const currentSiteLanguage = PageURLElements[6].includes('?') ? PageURLElements[6].substring(0, PageURLElements[6].indexOf('?')) : PageURLElements[6];
    // ULR : window.location.href - Query string not include
    // 'https://www.magroup-online.com/WL/HBA/DE/de'
    let currentPageURL = format('{0}//{1}/{2}/{3}/{4}/{5}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6]);
    if (currentPageURL.includes('?')) {
      // Remove query string from url
      currentPageURL = currentPageURL.substring(0, currentPageURL.indexOf('?'));
    }
    // Full ULR : window.location.href
    // 'https://www.magroup-online.com/WL/HBA/DE/de?version=STAGING'
    const pageFullURL = format('{0}//{1}/{2}/{3}/{4}/{5}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5], PageURLElements[6]);
    // referringURL :
    // 'https://www.magroup-online.com/WL/HBA/DE'
    const pageReferringURL = format('{0}//{1}/{2}/{3}/{4}', PageURLElements[0], PageURLElements[2], PageURLElements[3], PageURLElements[4], PageURLElements[5]);

    // Page Title
    if (this.props.homepage.data.title !== null || this.props.homepage.data.title !== undefined) {
      document.title = this.props.homepage.data.title;
    } else {
      document.title = 'Home Page';
    }


    window.digitalData = window.digitalData || {};
    window.digitalData = {
      environment: {
        name: currentEnvironment,
      },
      page: {
        pageInfo: {
          pageName: 'Homepage',
          pageType: 'Homepage',
          URL: currentPageURL,
          fullURL: pageFullURL,
          referringURL: pageReferringURL,
          title: document.title,
          server: currentServer,
          language: currentSiteLanguage,
        },
      },
    };
    // console.log('-- Home Page --');
    // console.log(window.digitalData);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize);
  }

  chooseBanner = () => (this.affiliation ? this.affiliation.background : this.props.homepage.banner);

  render() {
    if (!this.props.quoteForm || this.props.DCX === null) {
      return false;
    }
    return (
      <div className="homeSite">
        <div id="body-site" className="body-site">
          <div className="background-container">
            <div className="background-image" style={{ backgroundImage: `url(${this.chooseBanner()})` }} />
          </div>
          <div className="body-content">
            <div className="flex-display flex-display--home base-content">
              <QuoteFormContainer
                DCX={this.props.DCX}
                LOB={this.props.data.lineOfBusiness}
                quoteForm={this.props.quoteForm}
              />
              <div className="steps">
                <div
                  className={`title ${this.props.homepage.data.bannerTextPosition ? `title--${this.props.homepage.data.bannerTextPosition.toLowerCase()}` : ''}`}
                  tabIndex="0"
                  aria-label={this.props.homepage.data.mainImageText}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: this.props.homepage.data.mainImageText }}
                  />
                </div>
                <div 
                  className="subTitle" 
                  style={{ color: this.props.homepage.data.subTitleColor }} 
                  tabIndex="0"
                  aria-label={this.props.homepage.data.subTitle}
                >
                    {this.props.homepage.data.subTitle}
                </div>
                <div className="tiles-container">
                  <Tiles
                    title={this.props.homepage.data.block1Title}
                    color={this.props.homepage.data.block1Color}
                    content={this.props.homepage.data.block1Text}
                  />
                  <Tiles
                    title={this.props.homepage.data.block2Title}
                    color={this.props.homepage.data.block2Color}
                    content={this.props.homepage.data.block2Text}
                  />
                  <Tiles
                    title={this.props.homepage.data.block3Title}
                    color={this.props.homepage.data.block3Color}
                    content={this.props.homepage.data.block3Text}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  DCX: state.site.DCX,
}))(HomeSite));
