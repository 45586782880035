/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';

type Props = {
  names: string[],
  fields: Object[],
  changeForm: Function,
  min: number,
  max: number,
  placeholder: string,
}

type State = {
  open: boolean
}

export default class SingleSelectPassenger extends React.Component<Props, State> {
  state = {
    open: false,
  };
  static defaultProps = {
    min: 0,
    max: 99,
  };

  changeValue = (fieldName, value) => {
    this.props.changeForm(fieldName, value);
  };

  increment = (fieldName, value) => {
    if (value < this.props.max) this.changeValue(fieldName, value + 1);
  };

  decrement = (fieldName, value) => {
    if (value > this.props.min) this.changeValue(fieldName, value - 1);
  };

  handleKeyDown = (e, action, fieldName, value) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      action(fieldName, value);
    }
  };

  render() {
    const { value, name } = this.props.input;
    return (
      <div className="single-select-passenger">
        <div className="input-view">
          <span id={`${name}-value`} aria-live="polite">{value || 0}</span>
          <div className="values" aria-labelledby={`${name}-value`}>
            <button
              type="button"
              className="left"
              aria-label="Decrease value"
              tabIndex={0}
              onClick={() => this.decrement(`${name}`, value || 0)}
              onKeyDown={(e) => this.handleKeyDown(e, this.decrement, `${name}`, value || 0)}
            >
              -
            </button>
            <button
              type="button"
              className="right"
              aria-label="Increase value"
              tabIndex={0}
              onClick={() => this.increment(`${name}`, value || 0)}
              onKeyDown={(e) => this.handleKeyDown(e, this.increment, `${name}`, value || 0)}
            >
              +
            </button>
          </div>
        </div>
      </div>
    );
  }
}

