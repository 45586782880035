import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';
import { i18nReducer } from 'react-redux-i18n';
import { reducer as formReducer } from 'redux-form';
import quoteFormReducer from './quoteForm/quoteFormReducer';
import siteReducer from './site/siteReducer';

export default combineReducers({
  quoteForm: quoteFormReducer,
  site: siteReducer,
  form: formReducer,
  i18n: i18nReducer,
  notifications: notificationsReducer(),
});
