/* eslint-disable max-len */
// @flow
import { RestClient } from '../network';

const siteApi = {
  get: (partnerCode: string, countryCode: string, langCode: string, version: string, affiliationCode: string) =>
    new RestClient(`/site/${partnerCode}/${countryCode}/${langCode}?version=${version}${affiliationCode ? `&affiliation=${affiliationCode}` : ''}`, 'GET').execute(),
  getNavigation: (partnerCode: string, countryCode: string, langCode: string, version: string) =>
    new RestClient(`/site/navigation/${partnerCode}/${countryCode}/${langCode}?version=${version}`, 'GET').execute(),
  getDCX: (partner: string, country: string, language: string, version: string) =>
    new RestClient(`/site/dcx/${partner}/${country}/${language}?version=${version}`, 'GET').execute(),
  getSwitchLanguage: (lob, partner, country, lang) => new RestClient(`/site/${lob}/${partner}/${country}/${lang}/switches`, 'GET').execute(),
};

export default siteApi;
