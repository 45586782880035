// @flow

export type MainForm = {
  tripType: string,
  departureCountry: string,
  destination: string,
  travellers: number,
  startDate: string,
  endDate: string,
  minimumDays?: number,
  maximumDays?: number,
}

export type AdditionnalFields = {
  tripCost: boolean,
  bookingDate: boolean,
  rentalObject: boolean,
  relationshipStatus: boolean,
  destination: ?{
    type: string
  },
  eventName: boolean,
  carHireReference: boolean,
  travellers: ?{
    type: string
  },
  residenceCountry: ?{
    type: string
  },
  promoCode: boolean,
}

export type Geozone = {
  translation: string,
  code: string
}

export type LabelTranslation = {
  geozones?: Geozone[],
  titleLine1: string,
  titleLine2: string,
  tripType?: string,
  singleTrip?: string,
  annualTrip?: string,

  residenceCountry?: string,
  residenceCountryConfirmation?: string,
  residenceCountryErrorMsg?: string,
  selectResidenceCountry?: string,

  destination?: string,
  destinationErrorMsg?: string,
  selectDestination?: string,

  startDate?: string,
  startDateMsg?: string,

  endDate?: string,
  endDateMsg?: string,

  annualStartDate?: string,
  annualStartDateMsg?: string,

  annualEndDate?: string,
  annualEndDateMsg?: string,

  numberOfPassenger?: string,
  numberOfPassengerErrorMsg?: string,

  adult: string,
  adultAgeBandLabel: string,
  child: string,
  childAgeBandLabel: string,
  infant: string,
  infantAgeBandLabel: string,

  carHireReference: string,
  carHireReferenceErrorMsg?: string,
  selectCarHireReference?: string,
  rentalObject: string,
  rentalObjectErrorMsg: string,
  relationshipStatus: string,
  relationshipDescription: string,
  relationshipStatusErrorMsg: string,
  travellers: Object | string,

  family: string,
  single: string,
  couple: string,
  group: string,

  price: string,
  priceErrorMsg: string,

  eventDate: string,
  eventName: string,
  numberOfTickets: string,
  numberOfTicketsErrorMsg: string,
  bookingValue: string,
  bookingDate: string,
  bookingDateErrorMsg: string,
  selectNumber: string,
  hours: string,
  minutes: string,

  employeeTravelDuration: string,
  employeeTravelDurationErrorMsg?: string,
  lessThan?: string,
  moreThan?: string,
  numberOfTravellingDaysPerYear: string,
  numberOfTravellingDaysPerYearErrorMsg?: string,
  selectNumberOfTravellingDaysPerYear?: string,
  numberOfTravellingEmployee: string,
  insuranceStartDate: string,
  insuranceStartDateErrorMsg?: string,

  promoCode?: string,
  selectPromoCode?: string,
  getQuote: string,
  home: string,

}

export type Summary = {
  summary: string,
  insuranceCover: string,
  startDate: string,
  endDate: string,
  annualStartDate: string,
  annualEndDate: string,
  productName: string,
  travelFromCountry: string,
  travelTo: string,
  currencyDetails: string,
  traveller?: string,
  travellers?: string,
  detailMessage: string,
  termsAndConditions: string,
  ipid: string,
  totalPrice: string,
  promoCode: string,
}

export type ColorPickers = {
  fontColorTitle: string,
  callToAction: string,
  detailSummaryTitleColor: string,
  detailSummaryPanelColor: string,
}

export type QuoteFormType = {
  mainQuoteData: MainForm,
  additionnalFields: AdditionnalFields,
  quoteLabelTranslation: LabelTranslation,
  quoteSummaryTranslation: Summary,
  quoteStyle: ColorPickers,
}

export const LOBS = {
  AIRLINES: 'Airlines',
  TRAIN: 'Train',
  LODGING: 'Lodging',
  TICKETING: 'Ticketing',
  CAR_RENTAL: 'Car rental',
  LEGAL: 'Legal',
};
export type LOBSEnums = $Keys<typeof LOBS>;
