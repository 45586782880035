// @flow

import React from 'react';

export const HighlightedOffer = ({ color, background, description, title, children }) => {
  return (
    <div className="contain-highlight">
      <p>{description}</p>
      <div className="bloc-announce" style={{ color, background }}>{title}</div>
      {children}
    </div>
  );
};
