// @flow

import { addNotification as notify } from 'reapop';
import { I18n } from 'react-redux-i18n';

import handleError, {
  errorObject,
  jsonResponseCatcher,
  rawResponseCatcher,
  toJsonError,
  unwrap,
} from './handleError';
import toMessage from '../services/i18nService';
import { baseApiUrl } from '../const';

export function getAuthorization() {
  return `Bearer ${unwrap(sessionStorage.getItem('authorizationToken'))}`;
}

const stringify = (body) => {
  if (typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
};

export class HttpClient {
  apiExtensionUrl: string;
  headersValue: Object;
  methodvalue: string;
  bodyValue: ?string;

  constructor(apiExtensionUrl: string, method: string = 'GET') {
    this.apiExtensionUrl = apiExtensionUrl;
    this.headersValue = {};
    this.methodvalue = method;
    this.bodyValue = null;
  }

  headers(headers: Object) {
    Object.assign(this.headersValue, headers);
    return this;
  }

  body(body: string) {
    this.bodyValue = body;
    return this;
  }

  jsonBody(objectBody: Object) {
    this.headers({ 'Content-Type': 'application/json' });
    this.body(stringify(objectBody));
    return this;
  }

  execute() {
    // $FlowFixMe
    return fetch(baseApiUrl + this.apiExtensionUrl, {
      headers: this.headersValue,
      method: this.methodvalue,
      body: this.bodyValue,
    })
      .catch(rawResponseCatcher)
      .then(handleError);
  }
}

export class RestClient extends HttpClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
  }

  execute() {
    return super
      .execute()
      .then(jsonResponseCatcher)
      .catch(toJsonError);
  }
}

export class RestClientAuthenticated extends RestClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
    this.headers({ Authorization: getAuthorization() });
  }
}

export class MockClient {
  apiExtensionUrl: string;
  headersValue: Object;
  methodvalue: string;
  bodyValue: ?string;

  constructor(apiExtensionUrl: string, method: string = 'GET') {
    this.apiExtensionUrl = apiExtensionUrl;
    this.headersValue = {};
    this.methodvalue = method;
    this.bodyValue = null;
  }

  headers(headers: Object) {
    Object.assign(this.headersValue, headers);
    return this;
  }

  body(body: string) {
    this.bodyValue = body;
    return this;
  }

  jsonBody(objectBody: Object) {
    this.headers({ 'Content-Type': 'application/json' });
    this.body(stringify(objectBody));
    return this;
  }

  execute() {
    // $FlowFixMe
    return fetch(`http://localhost:9000${baseApiUrl}${this.apiExtensionUrl}`, {
      headers: this.headersValue,
      method: this.methodvalue,
      body: this.bodyValue,
    })
      .catch(rawResponseCatcher)
      .then(handleError);
  }
}

export function notifySuccess(reduxDispatch: Function) {
  return (response: Object) => {
    reduxDispatch(notify({
      message: 'Les modifications ont bien été prises en compte',
      status: 'success',
    }));
    return response;
  };
}


export function notifyError(reduxDispatch: Function, message: string) {
  return (error: Function) => {
    let errorResponse = error;
    if (typeof error.then !== 'function') {
      console.log(error);
      
      errorResponse = Promise.resolve(errorObject);
    }

    return (errorResponse: Object).then((errorJson) => {
      reduxDispatch(notify({
        message: message || I18n.t(`wsError.${errorJson.errorCode}`, toMessage(errorJson)),
        status: 'error',
      }));
      if (errorJson.errorCode === 'AUTHENTIFICATION_EXPIRED') {
        sessionStorage.clear();
      }
    });
  };
}

