/* eslint-disable react/jsx-closing-tag-location */
// @flow

import React from 'react';
import type { Summary } from '../../../types/QuoteFormTypes';
import type { ContractListRS } from '../../../types/QuotationTypes';
import { extractIpidDocumentUrl, extractTermAndConditionDocumentUrl } from '../../../network/api/eqsApi';

type Props = {
  offer: ContractListRS,
  quoteSummaryTranslation: Summary,
}

export default function TermsAndConditions(props: Props) {
  const termAndConditionDocumentUrl = extractTermAndConditionDocumentUrl(props.offer);
  const ipidDocumentUrl = extractIpidDocumentUrl(props.offer);

  return (
    <div className="terms-and-conditions">{`${props.quoteSummaryTranslation.detailMessage} `}
      {
        termAndConditionDocumentUrl &&
        <a
          href={termAndConditionDocumentUrl}
          target="_blank"
          rel="noopener noreferrer"
        >{props.quoteSummaryTranslation.termsAndConditions}</a>
      }
      {
        termAndConditionDocumentUrl && ipidDocumentUrl && <span> &amp; </span>
      }
      {
        ipidDocumentUrl &&
        <a
          href={ipidDocumentUrl}
          target="_blank"
          rel="noopener noreferrer"
        >{props.quoteSummaryTranslation.ipid}</a>
      }
    </div>
  );
}
