/* eslint-disable consistent-return */
// @flow

export const buildCommitPolicy = (paymentTransaction, commitPolicy) => ({
  ...commitPolicy,
  contractNumber: commitPolicy.contractNumber,
  ...creditCard(paymentTransaction, commitPolicy),
  ...directDebit(paymentTransaction),
});

export const creditCard = (paymentTransaction, commitPolicy) => {
  if (paymentTransaction.paymentResponse && paymentTransaction.paymentResponse.status === 'Success') {
    return ({
      transactionId: paymentTransaction.paymentResponse.transactionID,
      cardValidityDate: paymentTransaction.paymentResponse.expiryDate ?
        `${paymentTransaction.paymentResponse.expiryDate.year}-${(`0${paymentTransaction.paymentResponse.expiryDate.month}`).slice(-2)}` : null,
      payer: {
        firstName: commitPolicy.payer.firstName,
        lastName: commitPolicy.payer.lastName,
        email: commitPolicy.payer.email,
      },
    });
  }
};
export const directDebit = (paymentTransaction) => {
  if (paymentTransaction.directDebitStatus === 'Success' && paymentTransaction.directDebitResponse.checkboxOK) {
    return ({
      directDebit: {
        checkboxOk: paymentTransaction.directDebitResponse.checkboxOK,
        iban: {
          iban: paymentTransaction.directDebitResponse.iban,
        },
        accountHolderName: paymentTransaction.directDebitResponse.lastname,
      },
    });
  }
};
