// @flow
import React, { Fragment, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import NotificationsSystem from 'reapop';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import connect from 'react-redux/es/connect/connect';
import { I18n } from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import ConfirmationModal from './components/modaleNotification/ConfirmationModal';
import theme from './components/modaleNotification/theme/index';
import { HOME_PAGE_ROUTE } from './const';
import HomeSiteRoutes from './pages/homeSite/HomeSiteRoutes';
import { siteConfigAction, storeDCX } from './state/site/siteActions';
import siteApi from './network/api/siteApi';
import { queryParams, siteVersion } from './routes';
import type { DCXType } from '../../admin-ui/src/types/DCXTypes';
import Loader from './components/Loader';
import { errorObject } from './network/handleError';
import toMessage from './services/i18nService';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

type Props = {
  dispatch: Function,
  match: Object,
}

type State = {
  loading: boolean,
  errorMessage: ?string,
  cookieParam: Object,
  switches: Object,
}

class App extends React.Component<Props, State> {
  dataLoaded = false;
  dcxLoaded = false;

  state = {
    loading: true,
    errorMessage: null,
    cookieParam: null,
    switches: null,
  };

  updateLoadedStatus = () => {
    if (this.state.loading && this.dataLoaded && this.dcxLoaded) {
      this.setState({
        loading: false,
      });
    }
  };

  handleError = (error: Object) => {
    let errorResponse = error;
    if (typeof error.then !== 'function') {
      if (error.errorCode) {        
        errorResponse = Promise.resolve(error);
      } else {
        console.log('JS error', error);        
        errorResponse = Promise.resolve(errorObject);
      }
    }

    return (errorResponse: Object).then((errorJson) => {
      this.setState({
        errorMessage: I18n.t(`wsError.${errorJson.errorCode}`, toMessage(errorJson)),
        loading: false,
      });
    });
  };

  componentDidMount() {
    siteApi
      .get(
        this.props.match.params.partnerCode,
        this.props.match.params.countryCode,
        this.props.match.params.langCode,
        queryParams.version,
        queryParams.affiliateCode || null,
      )
      .then(resp => resp.json())
      .then((json) => {
        this.props.dispatch(siteConfigAction(json));
        document.title = json.homepage.data.title;
        document.documentElement.setAttribute('lang', this.props.match.params.langCode.toLowerCase());

        siteApi.getSwitchLanguage(
          json.data.lineOfBusiness,
          this.props.match.params.partnerCode,
          this.props.match.params.countryCode,
          this.props.match.params.langCode,
        )
          .then(response => response.json())
          .then((switches) => {
            this.setState({ switches });
          });

        this.dataLoaded = true;
        this.updateLoadedStatus();
      })
      .catch(this.handleError);

    siteApi
      .getDCX(
        this.props.match.params.partnerCode,
        this.props.match.params.countryCode,
        this.props.match.params.langCode,
        siteVersion,
      )
      .then(response => response.json())
      .then((dcx: DCXType) => {
        this.props.dispatch(storeDCX(dcx));
        this.dcxLoaded = true;
        this.updateLoadedStatus();
      })
      .catch(this.handleError);
  }

  render() {
    if (this.state.loading) {
      return (<div className="page-loading"><Loader /></div>);
    }

    if (this.state.errorMessage) {
      return (<div className="page-error">{this.state.errorMessage}</div>);
    }

    if (window.OneTrust) {
      window.OneTrust.changeLanguage(this.props.match.params.langCode);
    }

    return (
      <Fragment>
        <Helmet>
          <html lang={this.props.match.params.langCode} />
        </Helmet>
        <HomeSiteRoutes
          {...this.props}
          cookieParam={this.state.cookieParam}
          switches={this.state.switches}
        />
      </Fragment>
    );
  }
}

const WrappedApp = withRouter(connect()(App));

function RootApp() {

  useEffect(() => {
    const observer = new MutationObserver(() => {      
      const dialog = document.querySelector('div[role="alertdialog"][aria-describedby="onetrust-policy-text"]');
      if (dialog) {        
        dialog.setAttribute('aria-label', 'onetrust policy text');        
        observer.disconnect(); // Stop observing once the dialog is found and customized
      }
    });

    // Start observing the body for changes (child elements added or removed)
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Clean up observer on component unmount
  }, []);

  return (
    <div id="app">
      <a href="#body-site" className="skip-link">Skip to main content</a>
      <ReactNotifications />
      <ConfirmationModal />
      <div>
        <div className="content-container">
          <div id="main-content">
            <Switch>
              <Route path={HOME_PAGE_ROUTE} component={WrappedApp} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RootApp;
