// @flow
import { RestClient } from '../network';

const bookingPathApi = {
  config: () =>
    new RestClient('/site/bookingPathConst', 'GET').execute(),
  get: (params, lob, version) =>
    new RestClient(`/bookingPath/${lob}/${params.partnerCode}/${params.countryCode}/${params.langCode}/${params.step}?version=${version}`, 'GET').execute(),
  getContactPage: (params, lob) =>
    new RestClient(`/bookingPath/${lob}/${params.partnerCode}/${params.countryCode}/${params.langCode}/contact-us`, 'GET').execute(),
  getNoticePage: (params, lob) =>
    new RestClient(`/bookingPath/${lob}/${params.partnerCode}/${params.countryCode}/${params.langCode}/notice-page`, 'GET').execute(),

};

export default bookingPathApi;
